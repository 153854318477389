import React, { useRef, useState } from 'react';
import IconButton from './IconButton';
import { faCloudUploadAlt, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DarkSkyBlue, Redish } from 'libs/css';
import S3Upload from '@upshow/react-s3-uploader/s3upload';
import { getToken } from '@upshow/auth';
import { SIGNER_URL, STATIC_CDN_PATH } from '../constants';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DragAndDrop ({ children, mutate, path, showDelete = false, className = null, required_dimensions = {} }) {
    const [hover, setHover] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const fileInputField = useRef(null);

    const handleMouseIn = () => setHover(true);
    const handleMouseOut = () => setHover(false);
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter + 1);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };
    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(dragCounter - 1);
        if (dragCounter > 1) return;
        setDragging(false);
    };

    const handleProgress = (progress) => setUploading(progress);
    const handleError = (error) => {
        console.error(error);
        setUploading(false);
        toast.error(error);
    };

    const fileUrl = (s3Url, filename) => `${s3Url.endsWith('/') ? s3Url.slice(0, -1) : s3Url}/${filename}`;
    const handleFinish = async (result, file) => {
        const uploadedFile = Object.assign({ file, fileUrl: fileUrl(STATIC_CDN_PATH, result.filename), }, result);
        await mutate(uploadedFile.fileUrl);
        if (fileInputField.current) fileInputField.current.value = '';
        setUploading(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            e.persist();
            await handleNewFile(e.dataTransfer.files);
            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };
    const handleFileChange = async (files) => {
        await handleNewFile(files);
    };

    function handleNewFile (files) {
        const file = files[0];
        if (!file) return;

        const url = URL.createObjectURL(file);
        const img = new Image();

        img.onload = () => {
            const { width, height } = required_dimensions ?? {};

            if (width && img.width !== width) return handleError(`Width is not ${width}px`, file);
            if (height && img.height !== height) return handleError(`Height is not ${height}px`, file);

            upload(files);
        };

        img.src = url;
    }

    async function upload (files) {
        const token = await getToken();

        return new S3Upload({
            files: files,
            signingUrl: '/s3/assets/sign',
            s3path: path,
            contentDisposition: 'auto',
            uploadRequestHeaders: {},
            onFinishS3Put: handleFinish,
            onProgress: handleProgress,
            onError: handleError,
            signingUrlMethod: 'GET',
            accept: 'image/*',
            signingUrlHeaders: { authorization: 'Bearer ' + token },
            scrubFilename: (filename) => filename.replace(/[^\w\d_\-.]+/ig, ''),
            server: SIGNER_URL
        });
    }

    const displayHover = hover || dragging || uploading !== false;

    return <div
        className={`${className ?? ''} position-relative`}
        onMouseEnter={handleMouseIn}
        onMouseLeave={handleMouseOut}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
    >
        {children}
        <input type="file" className="d-none" ref={fileInputField} onChange={({ target }) => handleFileChange(target.files)} accept="image/*"/>
        {displayHover && <>
            <div className="position-absolute w-100 h-100 border bg-light" style={{ top: 0, left: 0, opacity: 0.7, backdropFilter: 'blur(5px)' }}/>
            <div className="position-absolute w-100 h-100 d-flex flex-row justify-content-around align-items-center" style={{ top: 0, left: 0 }}>
                {uploading !== false
                    ? <FontAwesomeIcon spin={true} icon={faSync} size="lg"/>
                    : dragging
                        ? <FontAwesomeIcon icon={faCloudUploadAlt} size="lg"/>
                        : <>
                            <IconButton icon={faCloudUploadAlt} onHoverColor={DarkSkyBlue} onClick={() => fileInputField.current.click()} size="lg"/>
                            {showDelete && <IconButton icon={faTrash} onHoverColor={Redish} onClick={() => mutate(null)} size="lg"/>}
                        </>
                }
            </div>
        </>}
    </div>;
}