import { useEffect, useMemo, useRef, useState } from 'react';
import { chunk } from './utils';

// Thanks @DanAbramov https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
const ITEMS_PER_PAGE = 5;
export const usePager = (list, totalDuration, play, itemsPerPage = ITEMS_PER_PAGE) => {
  const [pageIdx, setPageIdx] = useState(0);
  const pages = useMemo(() => chunk(list, itemsPerPage), [itemsPerPage, list]);
  const pageCount = pages.length;
  let pageDuration = null;
  if (play && pageCount) {
    pageDuration = totalDuration / pageCount;
  }
  useInterval(() => {
    if (pageIdx < pageCount - 1) {
      setPageIdx(pageIdx + 1);
    }
  }, pageDuration);
  useEffect(() => {
    setPageIdx(0);
  }, [play]);
  const page = pageIdx < pageCount ? pages[pageIdx] : [];
  return [page, pageIdx, pages];
};