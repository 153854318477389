import React from 'react';

function UPshowNowLogo ({ className }) {
    return (<svg version="1.1" viewBox="0 0 786 303" className={className}>
                <g>
                    <g>
                        <path fill="currentColor" d="M763.7,0.5H22.6C10.1,0.5-0.1,10.7-0.1,23.2v257.1c0,12.5,10.2,22.7,22.7,22.7h741.1
			c12.5,0,22.7-10.2,22.7-22.7V23.2C786.4,10.7,776.2,0.5,763.7,0.5z M219.1,221.4c0,9.9-2.7,32.8-27.6,32.8
			c-4.2,0-8.2-0.7-11.7-2.1c-3.6-1.4-7-3.6-10-6.5c-2.6-2.5-5.1-5.5-7.3-8.8c-1.9-2.9-3.8-5.8-5.7-8.8l-49.3-80.9v77.1
			c0,9-2.2,16.2-6.6,21.3c-4.7,5.6-11.1,8.5-18.6,8.5c-7.7,0-14.2-3-18.8-8.7c-4.2-5.2-6.3-12.3-6.3-21.2V81.6
			c0-7.1,0.8-12.7,2.4-17l0.1-0.2c2.1-5.3,5.6-9.8,10.5-13.1c5-3.4,10.5-5.2,16.3-5.2c4.5,0,8.6,0.9,12,2.6c3.2,1.6,6.1,3.7,8.5,6.4
			c2.1,2.3,4.1,5.1,6.2,8.5c1.8,2.9,3.6,6,5.5,9.2l50.8,82.3V76c0-9.1,2-16.1,6.1-21.2c4.5-5.7,10.9-8.7,18.6-8.7
			c7.7,0,14.2,3,18.8,8.6c4.2,5.2,6.2,12.1,6.2,21.3V221.4z M418.7,224c-8.5,9.5-19.2,17.3-31.5,22.8c-12.2,5.5-25.2,8.4-38.5,8.4
			c-28.1,0-52.1-10.4-71.4-31c-19.1-20.4-28.8-45.1-28.8-73.5c0-28.4,9.7-53.1,28.8-73.5c19.3-20.5,43.1-31,70.9-31
			c27.8,0,51.7,10.6,71,31.4C438.3,98.4,448,123,448,151C448,179.1,438.2,203.7,418.7,224z M727,83.1l-64.1,152.7
			c-7,15.9-22.5,15.1-22.5,15.1c-15.6,0.4-23-16.6-23-16.6l-24.6-57.9l-25.1,59.5c-9.1,17.6-26.1,14.8-26.1,14.8
			c-12.3,0.2-18.2-13.6-18.2-13.6L458.2,82.8c-2.4-6.3-2.4-12.7,0.1-18.7c2.5-6.2,7.2-10.8,13.5-13.2c4.9-2.3,11.9-2.5,18.1-0.1
			c6.4,2.5,11,7.1,13.4,13.5l42,99.2l24.6-58.6c4-9.7,12.1-15.1,22.7-15.1c10.6,0,18.7,5.3,22.9,14.9l24.7,58.7L682.2,64
			c2.7-6.2,7.3-10.7,13.4-13.1c3-1.2,6.1-1.8,9.2-1.8c3.1,0,6.2,0.6,9.3,1.8c6.2,2.5,10.8,7.1,13.3,13.5
			C729.8,70.6,729.7,77.1,727,83.1z"/>
                        <path fill="currentColor" d="M392.3,138.3L342,104.7c-12.1-8-28.6,0.2-28.6,14.2v67c0,14,16.6,22.2,28.6,14.2l50.3-33.5
			C402.7,159.8,402.7,145.2,392.3,138.3z"/>
                    </g>
                </g>
                <g style={{ display: 'none' }}>
                    <path className="st2" d="M763.7,303H22.6c-12.5,0-22.7-10.2-22.7-22.7V23.2c0-12.5,10.2-22.7,22.7-22.7h741.1
		c12.5,0,22.7,10.2,22.7,22.7v257.1C786.4,292.8,776.2,303,763.7,303z"/>
                    <g>
                        <path fill="currentColor" d="M523.3,236.9L458.2,82.8c-2.4-6.3-2.4-12.7,0.1-18.7c2.5-6.2,7.2-10.8,13.5-13.2
			c4.9-2.3,11.9-2.5,18.1-0.1c6.4,2.5,11,7.1,13.4,13.5l42,99.2l24.6-58.6c4-9.7,12.1-15.1,22.7-15.1c10.6,0,18.7,5.3,22.9,14.9
			l24.7,58.7L682.2,64c2.7-6.2,7.3-10.7,13.4-13.1c3-1.2,6.1-1.8,9.2-1.8l0,0c3.1,0,6.2,0.6,9.3,1.8c6.2,2.5,10.8,7.1,13.3,13.5
			c2.5,6.3,2.4,12.8-0.3,18.7l-64.1,152.7c-7,15.9-22.5,15.1-22.5,15.1c-15.6,0.4-23-16.6-23-16.6l-24.6-57.9l-25.1,59.5
			c-9.1,17.6-26.1,14.8-26.1,14.8C529.3,250.7,523.3,236.9,523.3,236.9z"/>
                        <path fill="currentColor" d="M419.2,77.7c-19.3-20.8-43.2-31.4-71-31.4c-27.8,0-51.6,10.4-70.9,31c-19.1,20.4-28.8,45.1-28.8,73.5
			c0,28.4,9.7,53.1,28.8,73.5c19.3,20.6,43.3,31,71.4,31c13.3,0,26.3-2.8,38.5-8.4c12.3-5.5,22.9-13.3,31.5-22.8
			c19.4-20.4,29.3-44.9,29.3-73C448,123,438.3,98.4,419.2,77.7z M392.3,166.7L342,200.2c-12.1,8-28.6-0.2-28.6-14.2v-67
			c0-14,16.6-22.3,28.6-14.2l50.3,33.5C402.7,145.2,402.7,159.8,392.3,166.7z"/>
                        <path fill="currentColor" d="M169.4,76v79.1l-50.8-82.3c-1.9-3.2-3.7-6.3-5.5-9.2c-2.1-3.5-4.1-6.2-6.2-8.5
			c-2.4-2.7-5.3-4.8-8.5-6.4c-3.5-1.7-7.5-2.6-12-2.6c-5.9,0-11.4,1.7-16.3,5.2c-4.9,3.4-8.4,7.8-10.5,13.1l-0.1,0.2
			c-1.6,4.4-2.4,10-2.4,17v142.6c0,8.9,2.1,16,6.3,21.2c4.6,5.7,11.2,8.7,18.8,8.7c7.4,0,13.8-3,18.6-8.5c4.4-5.1,6.6-12.3,6.6-21.3
			v-77.1l49.3,80.9c1.9,2.9,3.8,5.9,5.7,8.8c2.2,3.3,4.7,6.3,7.3,8.8c3,2.9,6.4,5.1,10,6.5c3.5,1.4,7.5,2.1,11.7,2.1
			c24.9,0,27.6-22.9,27.6-32.8V76c0-9.2-2-16.1-6.2-21.3c-4.6-5.6-11.1-8.6-18.8-8.6c-7.6,0-14,3-18.6,8.7
			C171.4,59.9,169.4,66.8,169.4,76z"/>
                    </g>
                </g>
            </svg>
    );
}

export default UPshowNowLogo;