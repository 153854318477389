import { gql } from '@apollo/client';
import { DEVICE_ONLINE_STATUS_FRAGMENT } from 'Components/DeviceOnlineStatus';

export const GET_DEVICE_INFO = gql`query getDeviceInfo($id: Int!) {
    backend {
        id
        Device(id: $id) {
            id name type health model uiVersion ip appVersion online pending_installation serial isVertical userAgent socketOnline
            activatedAt lastSeen ...DeviceOnlineStatus
            LiveChromeDevice { id: serialNumber model serialNumber deviceId orgUnitPath osVersion firmwareVersion platformVersion macAddress(nice: true) ethernetMacAddress(nice: true) screenshotFiles { name downloadUrl createTime } }
            skykit: LiveSkykitDevice { id deviceKey serial config { rotation kioskApp } state { isOffline installedApks { versionName appKey channel } webViewVersionName wifiSignal wifiSsid osVersion osBuildNumber macAddressEthernet(nice: true) macAddressWifi(nice: true) model volume } }
            organization { id name hasLiveEvents: has_feature(feature: "live_events") }
        }
    }
}
${DEVICE_ONLINE_STATUS_FRAGMENT}`;

export const GET_BASIC_DEVICE_INFO = gql`query getBasicDeviceInfo($id: Int!) {
    backend {
        id
        Device(id: $id) { id name type model serial organization { id name } skykit: SkykitDevice { id deviceKey } ...DeviceOnlineStatus }
    }
}
${DEVICE_ONLINE_STATUS_FRAGMENT}`;

export const SEND_COMMAND = gql`mutation sendCommand ($device_id: Int!, $command: String! $payload: JSON){
    notify_screen(device_id: $device_id, command: $command, payload: $payload) { successful message status  }
}`;

export const FIX_CORRUPTED_UI = gql`mutation fixCorruptedUI($deviceId: Int!){
    backendMutation {
        fixCorruptedUI(deviceId: $deviceId) {
            status
            successful
            message
        }
    }
}`;
export const GET_DEVICE_TIMELINE = gql`query getDeviceTimeline($device_id: Int! $first: Int $last: Int $after: String $before: String $actions: [String] $from: GraphQLDateTime $to: GraphQLDateTime) {
    backend {
        id
        Device(id: $device_id) {
            id
            Timeline(last: $last, first: $first, after: $after, before: $before actions: $actions from: $from to: $to){
                pageInfo {
                    startCursor

                    hasNextPage
                    endCursor
                }
                edges {
                    cursor
                    node {
                        id action timestamp
                        ...on SpeedTestMetric { speed mbits seconds }
                        ...on CommandMetric { command }
                        ...on KeepAliveMetric { ui_version uptime }
                        ...on StartMetric { ui_version }
                        ...on ErrorMetric { tags error_message payload error_stack }
                        ...on WarningMetric { tags error_message payload error_stack }
                        ...on SwapStateMetric {
                            inboundState { ID state_id name cn source takeover granularity script_id }
                            outboundState { ID state_id name }
                        }
                    }
                }
            }
        }
    }
}`;

export const GET_DEVICE_REAL_TIMELINE = gql`query eager_getDeviceRealTimeline($device_id: Int! $from: GraphQLDateTime $to: GraphQLDateTime) {
    backend {
        id
        Device(id: $device_id) {
            id
            RealTimeline(from: $from to: $to){
                interval
                buckets {
                    bucket_key
                    bucket_name
                    max_uptime
                    reboots
                }
            }
        }
    }
}`;

export const GET_DEVICE_AIRTIME = gql`query getDeviceAirtime($device_id: Int!, $time_amount: Int, $time_unit: String, $fixed_interval: String) {
    backend {
        id
        Device(id: $device_id) {
            id
            airtime(time_amount: $time_amount, time_unit: $time_unit)
            airtime_histogram(time_amount: $time_amount, time_unit: $time_unit, fixed_interval: $fixed_interval)
        }
    }
}`;
export const GET_CDN_INFO = gql`query getDeviceIdCDNUsage($device_id: Int! $daysRange: Int! $time_zone: String $isEngineer: Boolean!){
    backend { id
        Device(id: $device_id) {
            id uiSettings organization { id }
            CDNAnalytics(daysRange: $daysRange time_zone: $time_zone) {
                CDNUsage { bytes requests cost }
                CDNChart{ key_as_string cost requests bytes }
                SWMetrics
            }
            CachedRequests @include(if: $isEngineer) {
                ID timestamp Requests {
                    ID url method credentials mode cache redirect status redirected statusText ok etag content_length content_type last_modified last_accessed
                }
            }
        }
    }
}`;

export const DELETE_DEVICE = gql`mutation deleteDevice($device_id: Int!) {
    backendMutation { delete_device(device_id: $device_id) { successful message status }}
}`;

export const RENAME_DEVICE = gql`mutation renameDevice($id: Int!, $name: String!) {
    backendMutation {
        mutationResult: rename_device(device_id: $id, name: $name) {
            Device { id name }
            successful message
        }
    }
}`;

export const TAKE_SCREENSHOT = gql`mutation takeScreenshot($deviceId: Int!) {
    DeviceMutation(deviceId: $deviceId) {
        takeScreenshot {
            successful message commandId
        }
    }
}`;

export const IS_SCREENSHOT_READY = gql`query isScreenshotReady($deviceId: Int!, $commandId: String!) {
    backend { id
        Device(id: $deviceId) {
            id
            isChromeDeviceCommandReady(commandId: $commandId)
        }
    }
}`;

export const SCREENSHOTS = gql`query DeviceScreenshots($deviceId: Int!) {
    backend { id
        Device(id: $deviceId) {
            id model
            LiveChromeDevice { id: serialNumber screenshotFiles { name downloadUrl createTime } }
            SkykitScreenshots { id downloadUrl: url createTime: taken pending error }
        }
    }
}`;

export const REBOOT_DEVICE = gql`mutation rebootDevice($device_id: Int!) {
    DeviceMutation(deviceId: $device_id) {
        reboot {
            successful message
        }
    }
}`;