import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { genericMutationErrorHandler } from 'libs/genericMutationHandler';
import { ADD_ORG_USER, GET_USER_GROUPS } from '../api/queries/user';

import RoleGroupSelect from './RoleGroupSelect';
import { SmallLoading } from 'Layout/Loading';

const getOrgUserGroups = (org_id, data) => data?.backend.user.UserOrganizations.reduce((acc, userOrg) => {
    return userOrg?.organization?.id === org_id ? [...acc, ...userOrg.groups] : acc;
}, []);

export const UserUpdate = ({ children, org_id, user_id, onCompleted }) => {

    const [modal, setModal] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [loadUserGroups, {
        data: userGroupsData,
        loading: userGroupsLoading
    }] = useLazyQuery(GET_USER_GROUPS, {
        fetchPolicy: 'cache-and-network',
        variables: { user_id },
        onCompleted: (data) => setSelectedGroups(getOrgUserGroups(org_id, data)),
        notifyOnNetworkStatusChange: true
    });

    const [addUserMutation] = useMutation(ADD_ORG_USER, {
        variables: { org_id },
        onError: genericMutationErrorHandler,
        onCompleted: () => {
            onCompleted?.();
            loadUserGroups();
        }
    });

    useEffect(() => {
        if (modal) loadUserGroups();
    }, [modal]);

    const username = userGroupsData?.backend.user.username;
    const groups = getOrgUserGroups(org_id, userGroupsData);

    const toggle = () => setModal(!modal);

    const handleConfirmClick = async () => {
        await addUserMutation({
            variables: {
                user_id,
                groups: selectedGroups.map(group => +group.id)
            }
        });
        setModal(false);
    };

    const handleModalClose = () => setSelectedGroups(groups);

    return <>
        <span style={{ cursor: 'pointer' }} className="show-add-user-modal-btn" onClick={toggle}>
            {children}
        </span>
        <Modal className="UpdateUserModal" isOpen={modal} toggle={toggle} unmountOnClose={true} onClosed={handleModalClose}>
            <ModalHeader toggle={toggle}>{`Update user`}</ModalHeader>
            <ModalBody>
                {userGroupsLoading ? <SmallLoading/> : <>
                    <div className="mb-1">User</div>
                    <strong className="mb-2">{username ? `${username} (${user_id})` : user_id}</strong>
                    <div className="mt-2 mb-2">Groups</div>
                    <RoleGroupSelect value={selectedGroups} onChange={setSelectedGroups} loading={userGroupsLoading}/>
                </>}
            </ModalBody>
            <ModalFooter>
                <Button className="cancel-btn" color="transparent" onClick={toggle}>
                    Cancel
                </Button>
                {<Button
                        onClick={handleConfirmClick}
                        className="confirm-btn"
                        color="primary"
                >
                    Confirm
                </Button>}
            </ModalFooter>
        </Modal>
    </>;
};

export default UserUpdate;