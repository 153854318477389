import React, { useContext } from 'react';

import stringifyError from 'libs/stringifyError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Yellowish } from 'libs/css';
import { AppContext } from "./Home";
import { Button } from "reactstrap";

const Error = (props) => {
    const { version, graph_version } = useContext(AppContext);

    const reloadPage = () => {
        window.location.reload();
    }

    const logOut = () => {
        window.oktaAuth.signOut();
    }

    return <div className="d-flex flex-column w-100 justify-content-center align-items-center h-100">
        <h3><FontAwesomeIcon icon={faExclamationTriangle} color={Yellowish}/> Error</h3>
        <small>
            <pre className="mt-2 p-2 border border-2 rounded">{stringifyError(props.error)}</pre>
        </small>
        <small className="text-body-secondary my-2">
            Front: {version} - API: {graph_version}
        </small>
        <div>
            <Button size="xs" color="light" className="me-1" onClick={reloadPage} role="button">Reload the page</Button>
            <Button size="xs" color="light"  className="ms-1" onClick={logOut} role="button">Log Out</Button>
        </div>
    </div>;
};

export default Error;
