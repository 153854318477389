import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

/**
 * Returns a path from end to start of organizations
 * Intended to be used with Organization.parent_organizations
 * (applies reverse due to order on which parents are returned)
 * @param organizations
 * @returns {null|React.Component}
 * @constructor
 */

export function OrganizationsPath ({ organizations = [] }) {
    if (!organizations?.length) return null;

    return JSON.parse(JSON.stringify(organizations)).reverse()
        .map(org => <span key={org.id}>{org.name}</span>)
        .reduce((acc, x) => acc === null ? x : <>{acc} <FontAwesomeIcon icon={faChevronRight} size="xs"/> {x}</>, null);
}