import { BASE_PATH, NODE_ENV, SENTRY_DSN, SENTRY_ENABLED } from './constants';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './Layout/App';
import './Styles/index.scss';
import packageInfo from '../package.json';

const { name, version } = packageInfo;

Sentry.init({
    dsn: SENTRY_DSN,
    normalizeDepth: 8,
    enabled: SENTRY_ENABLED,
    environment: NODE_ENV,
    release: name + '@' + version
});

ReactDOM.render(
    <BrowserRouter basename={BASE_PATH}>
        <App/>
    </BrowserRouter>,
    document.getElementById('apollo_root')
);
