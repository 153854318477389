import { DateTime } from 'luxon';
const NPS_KEYS = ['overall_satisfaction', 'interior_cleanliness', 'exterior_cleanliness', 'friendliness_of_team', 'speed_of_service', 'quality_of_food', 'order_accuracy', 'temperature_of_food', 'enhanced_hygiene', 'likelihood_to_return', 'all_complaints_ratio'];
const last_updated = new Date().toISOString();
const getRandomNumber = (min, max) => Math.random() * (max - min) + min;
const getRandomBoolean = () => Math.random() > 0.4;
const calculateVariationPercentage = (current, previous) => {
  if (previous === 0) return 100;
  return current / previous * 100 - 100;
};
const rangeArray = (min, max) => Array(max).fill(null).map((_v, i) => i + min);
const employeesPreviewNames = ['Barry Allen', 'Bruce Banner', 'Bruce Wayne', 'Carol Danvers', 'Charles Xavier', 'Clark Kent', 'Clint Barton', 'Diana Prince', 'Dick Grayson', 'Frank Castle', 'Hal Jordan', 'Hank Pym', 'James Barnes', 'Jason Todd', 'Jean Grey', 'Johnny Blaze', 'Johnny Storm', 'Kal-El', 'Matthew Murdock', 'Miles Morales', 'Natasha Romanoff', 'Oliver Queen', 'Peter Parker', 'Peter Quill', 'Scott Summers', 'Selina Kyle', 'Steve Rogers', 'Susan Storm', 'Tony Stark', 'Wade Wilson'];
const employeeAmount = Array.from(Array(10)).map(() => getRandomNumber(50, 500));
const employeeDifference = Array.from(Array(10)).map(() => getRandomNumber(1, 50));
const employeeQuantity = Array.from(Array(10)).map(() => Math.ceil(getRandomNumber(5, 30)));
const employeeLoyalty = employeesPreviewNames.map(name => ({
  name,
  value: getRandomNumber(1, 100)
}));
const storesPreviewNames = ['Store 1', 'Store 2', 'Store 3', 'Store 4', 'Store 5', 'Store 6', 'Store 7', 'Store 8', 'Store 9', 'Store 10'];
const storeSales = Array.from(Array(10)).map(() => getRandomNumber(50, 500));
const storeSalesAvg = Array.from(Array(10)).map(() => getRandomNumber(5, 30));
const storeSalesLastYear = Array.from(Array(10)).map(() => getRandomNumber(50, 600));
const storeQuantity = Array.from(Array(10)).map(() => Math.ceil(getRandomNumber(5, 30)));
const storeTrainingCompletion = Array.from(Array(10)).map(() => Math.ceil(getRandomNumber(60, 100)));
const EmptyStateEmployees = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  ranking: i,
  name: 'N/A',
  employee: 'N/A',
  sales: '-',
  count: '-',
  location: '-'
}));
const EmptyStateStores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  ranking: i,
  location: 'N/A',
  name: 'N/A'
}));
const DailySalesPreviewData = {
  sales: 0,
  goal: 1000,
  stretch_goal: null
};
const EmptyStateHuddleBoard = {
  drive_thru_sos: {
    goal: null,
    value: null,
    last_updated: null
  },
  all_complaints_ratio: {
    goal: null,
    value: null,
    last_updated: null
  },
  overall_satisfaction: {
    goal: null,
    value: null,
    last_updated: null
  },
  order_accuracy: {
    goal: null,
    value: null,
    last_updated: null
  },
  likelihood_to_return: {
    goal: null,
    value: null,
    last_updated: null
  },
  speed_of_service_score: {
    goal: null,
    value: null,
    last_updated: null
  }
};
const EmptyStateTrainings = {
  employees: rangeArray(1, 10).map(() => ({
    name: 'N/A',
    completed: true
  })),
  ext_course_id: 'Preview_course_id',
  title: 'N/A'
};
const BlazinByStorePreviewData = {
  top: [{
    name: 'Store 1',
    value: 42,
    ranking: 1
  }, {
    name: 'Store 2',
    value: 23.4,
    ranking: 2
  }, {
    name: 'Store 3',
    value: 22.6,
    ranking: 3
  }, {
    name: 'Store 4',
    value: 20,
    ranking: 4
  }, {
    name: 'Store 5',
    value: 19,
    ranking: 5
  }, {
    name: 'Store 6',
    value: 18.9,
    ranking: 6
  }, {
    name: 'Store 7',
    value: 17.4,
    ranking: 7
  }, {
    name: 'Store 8',
    value: 16.3,
    ranking: 8
  }, {
    name: 'Store 9',
    value: 15.5,
    ranking: 9
  }, {
    name: 'Store 10',
    value: 14.5,
    ranking: 10
  }],
  own: {
    name: 'store 4',
    value: 20,
    ranking: 4
  }
};
const EmployeesAvgTicketPreviewData = employeesPreviewNames.map((name, index) => ({
  employee: name,
  avg: employeeAmount[index],
  delta: employeeDifference[index]
})).sort((a, b) => b.avg - a.avg).map((obj, index) => ({
  ...obj,
  ranking: index + 1,
  isNew: index === 0
}));
const SpeedOfServicePreviewData = rangeArray(1, 30).map((pos, index) => ({
  location: `Store ${pos}`,
  value: Math.trunc(employeeAmount[index]),
  goal: 150,
  last_updated
})).sort((a, b) => a.value - b.value).map((obj, index) => ({
  ...obj,
  ranking: index + 1,
  isNew: index === 0
}));
const ProductSalesLeaderboardPreviewData = employeesPreviewNames.map((name, index) => ({
  name,
  sales: employeeAmount[index],
  count: employeeQuantity[index],
  location: Math.trunc(getRandomNumber(1234, 9876))
})).sort((a, b) => b.sales - a.sales).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const EmployeesAvgGuestSpend = employeesPreviewNames.map((name, index) => ({
  name: employeesPreviewNames[index],
  sales: employeeAmount[index],
  guests: employeeQuantity[index]
})).sort((a, b) => b.sales - a.sales).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const SameStoreSalesPreviewData = storesPreviewNames.map((location, index) => ({
  location,
  sales: storeSales[index],
  previous_sales: storeSalesLastYear[index],
  variation: calculateVariationPercentage(storeSales[index], storeSalesLastYear[index])
})).sort((a, b) => b.variation - a.variation).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const ProductSalesByStorePreviewData = storesPreviewNames.map((location, index) => ({
  name: location,
  sales: storeSales[index],
  count: storeQuantity[index]
})).sort((a, b) => b.sales - a.sales).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const LocationAvgPreviewData = storesPreviewNames.map((location, index) => ({
  location,
  net: storeSalesAvg[index]
})).sort((a, b) => b.net - a.net).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const TrainingByStorePreviewData = storesPreviewNames.map((location, index) => ({
  location,
  value: storeTrainingCompletion[index]
})).sort((a, b) => b.value - a.value).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const PreviewDataTrainings = {
  employees: employeesPreviewNames.map(name => ({
    name,
    completed: getRandomBoolean()
  })),
  ext_course_id: '1',
  title: 'Course',
  last_updated
};
const LocationsSalesGrowthPreviewData = [{
  location_name: 'BK_name_1',
  location_id: 27809,
  current_sales: 820331,
  previous_sales: 750932
}, {
  location_name: 'BK_name_2',
  location_id: 27810,
  current_sales: 640769,
  previous_sales: 721560
}, {
  location_name: 'BK_name_3',
  location_id: 27811,
  current_sales: 410789,
  previous_sales: 390780
}, {
  location_name: 'BK_name_4',
  location_id: 27812,
  current_sales: 980345,
  previous_sales: 920909
}, {
  location_name: 'BK_name_5',
  location_id: 27813,
  current_sales: 1098003,
  previous_sales: 995037
}, {
  location_name: 'BK_name_6',
  location_id: 27814,
  current_sales: 989087,
  previous_sales: 926994
}, {
  location_name: 'BK_name_7',
  location_id: 27815,
  current_sales: 745098,
  previous_sales: 714004
}, {
  location_name: 'BK_name_8',
  location_id: 27816,
  current_sales: 819654,
  previous_sales: 818880
}, {
  location_name: 'BK_name_9',
  location_id: 27817,
  current_sales: 8388,
  previous_sales: 7000
}, {
  location_name: 'BK_name_10',
  location_id: 27818,
  current_sales: 456992,
  previous_sales: 501456
}, {
  location_name: 'BK_name_11',
  location_id: 27819,
  current_sales: 200238,
  previous_sales: 0
}, {
  location_name: 'BK_name_12',
  location_id: 27820,
  current_sales: 0,
  previous_sales: 456881,
  my_own_location: true
}];
const ShiftLogPreviewData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  status: '-',
  category: 'N/A',
  description: 'N/A',
  own: 'own',
  time: '-'
}));
const LocationsAvgTicketPreviewData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  location: 'N/A',
  gross: null,
  net: null,
  ranking: i,
  last_updated: null
}));
const LocationsCarCountEmptyData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  location: 'N/A',
  car_count: null,
  ranking: i,
  daypart: 'ALL',
  goal: null,
  last_updated: null
}));
const LocationsCarCountPreviewData = storesPreviewNames.map((store_name, i) => ({
  location: store_name,
  car_count: Math.ceil(getRandomNumber(1, 50)),
  ranking: i + 1,
  daypart: 'ALL',
  goal: 0,
  last_updated
})).sort((a, b) => b.car_count - a.car_count).map((obj, index) => ({
  ...obj,
  ranking: index + 1
}));
const SpeedOfServiceEmptyData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  location: 'N/A',
  value: null,
  ranking: i,
  daypart: 'ALL',
  goal: null,
  last_updated: null
}));
const NpsPreviewData = {
  location: 1,
  last_updated,
  data: NPS_KEYS.reduce((acc, key) => ({
    ...acc,
    [key]: Math.ceil(getRandomNumber(80, 100))
  }), {})
};
const NpsEmptyData = {
  location: 1,
  last_updated: null,
  data: {}
};
const HuddleBoardPreviewData = {
  drive_thru_sos: {
    goal: Math.floor(getRandomNumber(90, 100)),
    value: Math.floor(getRandomNumber(80, 90)),
    last_updated
  },
  all_complaints_ratio: {
    goal: Math.floor(getRandomNumber(8, 10)),
    value: Math.floor(getRandomNumber(11, 12)),
    last_updated
  },
  overall_satisfaction: {
    goal: Math.floor(getRandomNumber(8, 10)),
    value: Math.floor(getRandomNumber(11, 12)),
    last_updated
  },
  order_accuracy: {
    goal: Math.floor(getRandomNumber(8, 10)),
    value: Math.floor(getRandomNumber(11, 12)),
    last_updated
  },
  likelihood_to_return: {
    goal: Math.floor(getRandomNumber(8, 10)),
    value: Math.floor(getRandomNumber(11, 12)),
    last_updated
  },
  speed_of_service_score: {
    goal: Math.floor(getRandomNumber(8, 10)),
    value: Math.floor(getRandomNumber(11, 12)),
    last_updated
  }
};
const BlazinAttachmentPreviewData = {
  last_week: getRandomNumber(10, 100),
  this_week: getRandomNumber(30, 100),
  employees: employeeLoyalty.sort((a, b) => b.value - a.value).map((obj, index) => ({
    ...obj,
    ranking: index + 1
  })),
  last_updated
};
const CommentsEmptyPreviewData = null;
const CommentsPreviewData = [{
  comment: 'I liked my food and the man at the window was very friendly, but I was missing the sauces I requested.',
  rating: 3,
  timestamp: DateTime.utc().minus({
    days: 1
  }).toISO()
}];
const EmployeeLoyaltyLeaderboardPreviewData = employeesPreviewNames.map(name => ({
  name,
  value: Math.floor(getRandomNumber(0, 100))
})).sort((a, b) => b.value - a.value).map((employee, idx) => ({
  ...employee,
  ranking: idx + 1
}));
const EmployeeLoyaltyLeaderboardEmptyState = rangeArray(1, 30).map((_element, idx) => ({
  name: 'N/A',
  value: '-',
  ranking: idx + 1
}));
const getEmptyPreviewData = type => {
  switch (type) {
    case 'boh_type_avg_check_size':
      return {
        employees: EmptyStateEmployees
      };
    case 'boh_type_avg_guest_spend':
      return {
        employees: EmptyStateEmployees
      };
    case 'boh_type_product_sales_leaderboard':
      return {
        employees: EmptyStateEmployees,
        group_name: 'Items'
      };
    case 'boh_type_same_store_sales':
      return {
        top: EmptyStateStores
      };
    case 'boh_type_product_sales_by_store':
      return {
        top: EmptyStateStores,
        group_name: 'Items'
      };
    case 'boh_type_thermometer_sales':
    case 'boh_type_percentage_to_goal':
      {
        return {
          ...DailySalesPreviewData
        };
      }
    case 'boh_type_loyalty_gauge':
      {
        return {
          goal: 80,
          value: 0
        };
      }
    case 'boh_type_training_course':
      return EmptyStateTrainings;
    case 'boh_type_training_by_store':
      return {
        top: EmptyStateStores,
        course_name: 'Course'
      };
    case 'boh_type_customer_comments':
      return {
        comments: CommentsEmptyPreviewData
      };
    case 'boh_type_locations_sales_growth':
      return {
        boh_sales_locations: LocationsSalesGrowthPreviewData
      };
    case 'boh_type_shift_log':
      return {
        shift_logs: ShiftLogPreviewData
      };
    case 'boh_type_location_avg_ticket':
      return {
        top: LocationsAvgTicketPreviewData,
        own: LocationsAvgTicketPreviewData[0]
      };
    case 'boh_type_car_count':
      return {
        top: LocationsCarCountEmptyData
      };
    case 'boh_type_drive_thru_sos':
      return {
        top: SpeedOfServiceEmptyData,
        own: LocationsCarCountEmptyData[0],
        daypart: 'lunch'
      };
    case 'boh_type_store_speed_of_service':
      return {
        top: SpeedOfServiceEmptyData,
        own: SpeedOfServiceEmptyData[0],
        daypart: 'lunch'
      };
    case 'boh_type_nps':
      return {
        ...NpsEmptyData
      };
    case 'boh_type_huddle_board':
      return {
        data: EmptyStateHuddleBoard
      };
    case 'boh_type_blazin_by_store':
      return {
        top: EmptyStateStores
      };
    case 'boh_type_birthday':
    case 'boh_type_anniversary':
      return {
        employees: [{
          name: 'N/A'
        }]
      };
    case 'boh_type_blazin_attachment':
      return {
        last_week: 0,
        this_week: 0,
        employees: rangeArray(1, 15).map(v => ({
          ranking: v,
          name: 'N/A',
          value: '-'
        })),
        last_updated: null
      };
    case 'boh_type_employee_loyalty_leaderboard':
      return {
        employees: EmployeeLoyaltyLeaderboardEmptyState
      };
    default:
      return {};
  }
};
export const getPreviewData = (type, showEmpty, metadata) => {
  var _metadata$boh_goals3, _metadata$boh_goals3$;
  if (showEmpty) return getEmptyPreviewData(type);
  switch (type) {
    case 'boh_type_location_avg_ticket':
      return {
        top: LocationAvgPreviewData,
        own: LocationAvgPreviewData[1],
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_avg_check_size':
      return {
        employees: EmployeesAvgTicketPreviewData,
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_avg_guest_spend':
      return {
        employees: EmployeesAvgGuestSpend,
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_product_sales_leaderboard':
      return {
        employees: ProductSalesLeaderboardPreviewData,
        group_name: 'Items',
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_same_store_sales':
      return {
        top: SameStoreSalesPreviewData,
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_product_sales_by_store':
      return {
        top: ProductSalesByStorePreviewData,
        group_name: 'Items',
        last_row: DateTime.utc().toISO()
      };
    case 'boh_type_training_course':
      return {
        ...PreviewDataTrainings
      };
    case 'boh_type_training_by_store':
      return {
        top: TrainingByStorePreviewData,
        own: TrainingByStorePreviewData[0],
        course_name: 'Course',
        updated_at: DateTime.utc().toISO()
      };
    case 'boh_type_car_count':
      return {
        top: LocationsCarCountPreviewData
      };
    case 'boh_type_thermometer_sales':
    case 'boh_type_percentage_to_goal':
      {
        var _metadata$boh_goals, _metadata$boh_goals$a, _metadata$boh_goals2, _metadata$boh_goals2$;
        const goal = (_metadata$boh_goals = metadata.boh_goals) === null || _metadata$boh_goals === void 0 ? void 0 : (_metadata$boh_goals$a = _metadata$boh_goals.all_days) === null || _metadata$boh_goals$a === void 0 ? void 0 : _metadata$boh_goals$a.goal;
        const stretchGoal = (_metadata$boh_goals2 = metadata.boh_goals) === null || _metadata$boh_goals2 === void 0 ? void 0 : (_metadata$boh_goals2$ = _metadata$boh_goals2.all_days) === null || _metadata$boh_goals2$ === void 0 ? void 0 : _metadata$boh_goals2$.stretch;
        return {
          ...DailySalesPreviewData,
          sales: goal || stretchGoal ? Math.max(goal !== null && goal !== void 0 ? goal : -1, stretchGoal !== null && stretchGoal !== void 0 ? stretchGoal : -1) : DailySalesPreviewData.goal
        };
      }
    case 'boh_type_loyalty_gauge':
      return {
        goal: (_metadata$boh_goals3 = metadata.boh_goals) === null || _metadata$boh_goals3 === void 0 ? void 0 : (_metadata$boh_goals3$ = _metadata$boh_goals3.all_days) === null || _metadata$boh_goals3$ === void 0 ? void 0 : _metadata$boh_goals3$.goal,
        value: 90
      };
    case 'boh_type_birthday':
    case 'boh_type_anniversary':
      return {
        employees: [{
          name: 'Clark Kent',
          birthday: DateTime.utc().toFormat('MM-dd'),
          start_date: DateTime.utc().minus({
            years: 1
          }).toFormat('yyyy-MM-dd')
        }]
      };
    case 'boh_type_drive_thru_sos':
      return {
        top: SpeedOfServicePreviewData,
        own: SpeedOfServicePreviewData[0],
        daypart: 'lunch',
        dob: last_updated
      };
    case 'boh_type_store_speed_of_service':
      return {
        top: SpeedOfServicePreviewData,
        own: SpeedOfServicePreviewData[0],
        daypart: 'lunch',
        dob: last_updated
      };
    case 'boh_type_customer_comments':
      return {
        comments: CommentsPreviewData
      };
    case 'boh_type_locations_sales_growth':
    case 'boh_type_shift_log':
    case 'boh_type_nps':
      return {
        ...NpsPreviewData
      };
    case 'boh_type_huddle_board':
      return {
        data: HuddleBoardPreviewData
      };
    case 'boh_type_blazin_by_store':
      return {
        top: BlazinByStorePreviewData.top,
        own: BlazinByStorePreviewData.own
      };
    case 'boh_type_blazin_attachment':
      return {
        ...BlazinAttachmentPreviewData
      };
    case 'boh_type_employee_loyalty_leaderboard':
      return {
        employees: EmployeeLoyaltyLeaderboardPreviewData,
        last_updated
      };
    case 'boh_type_payday':
      return {
        pay_days: [{
          pay_day_dob: DateTime.utc().toFormat('yyyyMMdd')
        }]
      };
    default:
      return {};
  }
};