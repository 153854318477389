import React, { useCallback, useContext, useState } from 'react';
import { AppContext } from './Home';
import logo from '../Styles/UPshow_badge_primary.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redish } from 'libs/css';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function Snippet ({ title, value }) {
    return <div className="mx-2 text-center border-end pe-3">
        <small className="text-body-secondary single-line-ellipsis">{title}</small>
        <br/>
        {value}
    </div>;
}

export function Symfony () {
    const { version, user, graph_version } = useContext(AppContext);

    const [show, setShow] = useState(window.localStorage.getItem('show-symfony') === 'true' ?? false);

    const toggleShowSymfony = useCallback((show) => {
        setShow(show);
        window.localStorage.setItem('show-symfony', show);
    }, []);

    return <div className="position-absolute border bg-light" style={{ zIndex: 10000, height: 38, right: 0, bottom: 0, width: (show ? '100%' : '48px'), lineHeight: 1.1, overflowX: 'scroll', overflowY: 'hidden' }}>
        <small className="d-flex p-1 position-relative">
            {show && <>
                <Snippet title="Version" value={version}/>
                <Snippet title="GraphQL" value={graph_version}/>
                <Snippet title="User" value={user?.username ?? <FontAwesomeIcon color={Redish} icon={faExclamationTriangle} title="unknown user"/>}/>
                <Snippet title="Grid Size" value={<ResponsiveBreakpoint/>}/>
            </>}

            <a onClick={() => toggleShowSymfony(!show)} className="p-1 ms-auto manito">
                <img src={logo} width={30} alt="logo"/>
            </a>
        </small>
    </div>;
}

function ResponsiveBreakpoint () {
    return <>
        <span className="d-inline d-sm-none">XS</span>
        <span className="d-none d-sm-inline d-md-none">SM</span>
        <span className="d-none d-md-inline d-lg-none">MD</span>
        <span className="d-none d-lg-inline d-xl-none">LG</span>
        <span className="d-none d-xl-inline">XL</span>
    </>;
}
