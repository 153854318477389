import { toast } from 'react-toastify';

export function genericMutationErrorHandler(error) {
    console.error(error);
    toast.error(error.toString());
}

export function genericMutationCompletedHelper(genericMutationResult, successMessage, onSuccess) {
    const { successful, message } = genericMutationResult;

    if (!successful) {
        console.error(message);
        toast.error(message);
    } else {
        toast.success(successMessage ?? message);
        if (onSuccess) onSuccess()
    }

    return successful;
}
