import { SIGNER_URL } from '../constants';
import React from 'react';
import isEqual from 'lodash/isEqual';
import S3Upload from '@upshow/react-s3-uploader/s3upload';
import { getToken } from '@upshow/auth';

export default class ButtonS3Uploader extends React.Component {

    static defaultProps = {
        className: 'react-dropzone-s3-uploader',
        isImage: (filename) =>
            filename && filename.match(/\.(jpeg|jpg|gif|png|svg)/i),
        notDropzoneProps: [
            'loading',
            'onFinish',
            's3UrlImage',
            'filename',
            'host',
            's3UrlVideo',
            'uploadImage',
            'uploadVideo',
            'isImage',
            'notDropzoneProps',
        ],
        style: {},
        activeStyle: {},
        rejectStyle: {},
        required_dimensions: {}
    };

    constructor (props) {
        super(props);
        const uploadedFiles = [];
        this.state = {
            uploadedFiles,
            uploaderOptions: null,
            s3Url: props.s3UrlImage,
        };
    }

    componentDidUpdate = (preProps, preState) => {
        if (!isEqual(preState.uploaderOptions, this.state.uploaderOptions)) {
            new S3Upload(this.state.uploaderOptions); // eslint-disable-line
        }
    };

    handleProgress = (progress, textState, file) => {
        this.props.onProgress?.(progress, textState, file);
        this.setState({ progress });
    };

    handleError = (err, file) => {
        console.log('ERROR', err);
        this.props.onError?.(err, file);
        this.setState({ error: err, progress: null });
    };

    handleFinish = (info, file) => {
        const uploadedFile = Object.assign(
            {
                file,
                fileUrl: this.fileUrl(this.props.s3UrlImage, info.filename),
            },
            info
        );

        const uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({ uploadedFiles, error: null, progress: null }, () => {
            this.props.onFinish && this.props.onFinish(uploadedFile);
        });
    };

    getImageUploadSettings = (token) => {
        return {
            s3path: this.props.path + '/',
            signingUrlMethod: 'GET',
            accept: 'image/*',
            signingUrlHeaders: { authorization: 'Bearer ' + token },
            contentDisposition: 'auto',
            scrubFilename: (filename) => filename.replace(/[^\w\d_\-.]+/ig, ''),
            server: SIGNER_URL,
            signingUrl: '/s3/assets/sign',
            preprocess: (file, next) => next(file),
        };
    };
    handleDrop = async (files, rejectedFiles) => {

        const token = await getToken();

        const uploaderOptions = Object.assign(
            {
                signingUrl: '/s3/sign',
                s3path: '',
                contentDisposition: 'auto',
                uploadRequestHeaders: {},
                onFinishS3Put: this.handleFinish,
                onProgress: this.handleProgress,
                onError: this.handleError,
            },
            this.getImageUploadSettings(token)
        );

        const options = { files, ...uploaderOptions, };

        this.setState({ s3Url: this.props.s3UrlImage }, () => {
            this.setState({
                uploadedFiles: [],
                error: null,
                progress: null,
                uploaderOptions: options,
            });
        });

        if (typeof this.props.onDrop === 'function') {
            this.props.onDrop(files, rejectedFiles);
        }
    };

    fileUrl = (s3Url, filename) => `${s3Url.endsWith('/') ? s3Url.slice(0, -1) : s3Url}/${filename}`;

    handleFile = (event) => {

        const file = event.target.files[0];

        if (!file) return;

        if (file.type.match('image/*')) {
            const url = URL.createObjectURL(file);
            const img = new Image();

            img.onload = () => {
                const { width, height } = this.props.required_dimensions ?? {};

                if (width && img.width !== width) return this.handleError(`Width is not ${width}px`, file);
                if (height && img.height !== height) return this.handleError(`Height is not ${height}px`, file);

                this.handleDrop([file]);

                this.props.onStart();
            };

            img.src = url;
        } else {
            this.handleDrop([file]);
        }
    };

    render () {
        const { ...dropzoneProps } = this.props;

        this.props.notDropzoneProps.forEach((prop) => delete dropzoneProps[prop]);

        return <input type="file" onChange={this.handleFile} accept={this.props.accept} required={this.props.isRequired} className={this.props.className}/>;
    }
}
