import { FormGroup, Input, Label } from "reactstrap";
import React from "react";

import ToolTipCito from "./ToolTipCito";

export const InlineSwitch = ({label, tooltip, ...props}) => {
    return <FormGroup switch inline>
        <Label>{label}
            <Input type="switch" role="switch" {...props}/>
            {tooltip && <ToolTipCito text={tooltip}/>}
        </Label>
    </FormGroup>
}