import { DateTime, Duration } from 'luxon';
export const THERMOMETER_TUBE_BG_MAX = 430;
export const getThermometerHeight = percentage => {
  return percentage < 100 ? percentage * THERMOMETER_TUBE_BG_MAX / 100 : THERMOMETER_TUBE_BG_MAX;
};
export const getSalesGoalsData = (BOHGoals, data) => {
  var _BOHGoals$goalKey, _data$units;
  const goalKey = BOHGoals !== null && BOHGoals !== void 0 && BOHGoals.is_all_days ? 'all_days' : DateTime.local().weekdayLong.toLowerCase();
  const goals = (_BOHGoals$goalKey = BOHGoals === null || BOHGoals === void 0 ? void 0 : BOHGoals[goalKey]) !== null && _BOHGoals$goalKey !== void 0 ? _BOHGoals$goalKey : {};
  return {
    units: (_data$units = data.units) !== null && _data$units !== void 0 ? _data$units : 0,
    goal: goals.goal ? +goals.goal : data.goal,
    stretch_goal: goals.stretch ? +goals.stretch : data.stretch_goal,
    sales: data.sales ? Math.round(+data.sales) : 0
  };
};
export const getPercentagesData = (BOHGoals, data) => {
  var _BOHGoals$goalKey2;
  const goalKey = BOHGoals !== null && BOHGoals !== void 0 && BOHGoals.is_all_days ? 'all_days' : DateTime.local().weekdayLong.toLowerCase();
  const goals = (_BOHGoals$goalKey2 = BOHGoals === null || BOHGoals === void 0 ? void 0 : BOHGoals[goalKey]) !== null && _BOHGoals$goalKey2 !== void 0 ? _BOHGoals$goalKey2 : {};
  return {
    goal: goals.goal ? +goals.goal : data.goal,
    percentage: data.value ? data.value : 0
  };
};
export const getSalesGoalsConditions = (salesData, customIsFinished = false) => {
  const {
    goal,
    stretch_goal,
    sales
  } = salesData;
  const goalReached = sales >= goal;
  const hasStretchGoal = !!stretch_goal;
  const stretchGoalReached = hasStretchGoal && sales >= stretch_goal;
  const showStretchGoal = !!stretch_goal && stretch_goal >= goal && sales >= goal;
  const showCongratulations = customIsFinished && sales >= goal || (hasStretchGoal ? stretchGoalReached : goalReached);
  const showTotalSales = stretchGoalReached;
  const showGoalUnreached = customIsFinished && sales < goal;
  return {
    showStretchGoal,
    showCongratulations,
    showTotalSales,
    showGoalUnreached
  };
};
export const getOrdinalSuffixedNumber = i => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) return `${i}st`;
  if (j === 2 && k !== 12) return `${i}nd`;
  if (j === 3 && k !== 13) return `${i}rd`;
  return `${i}th`;
};
export const formatDuration = time => Duration.fromObject({
  second: Math.abs(time)
}).toFormat('m:ss');
export const parseWithoutZeros = value => value.toFixed(2).replace(/\.0+$/, '');