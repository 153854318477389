import _ from 'lodash';

export default function stringifyError (error) {
    console.error(error);

    if (!error) return null;

    const graphqlNetworkError = _.get(error, 'networkError.result.errors[0].message');
    if (graphqlNetworkError) {
        return 'Network Error: ' + graphqlNetworkError;
    }

    if (error.code === 'ERR_NETWORK' && error.config) {
        return `Network error:\n${error.config.url}`
    }

    if (error.message) {
        if (error.message.includes('Cannot query field')) {
            return 'Front - API version mismatch; please refresh and try again.';
        }

        return error.message;
    }

    return `Unknown error: ${error.toString()}`;
}
