import React from 'react';

import stringifyError from 'libs/stringifyError';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ErrorModal = (props) => {
    return (
        <Modal {...props} className="ErrorModal">
                    <ModalHeader toggle={props.toggle}>Add user</ModalHeader>
                    <ModalBody className="text-wrap text-break">
                        <h3>Error</h3>
                        {stringifyError(props.error)}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="close-btn"
                            color='primary'
                            onClick={props.toggle}
                        >
                            Close
                            </Button>
                    </ModalFooter>
            </Modal>
    );
}

export default ErrorModal;