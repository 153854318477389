import React from 'react';

import { TailSpin } from 'react-loader-spinner';
import logo from '../Styles/UPshow_badge_primary.svg';

const Loading = () => {
    return <div className="d-flex flex-column w-100 justify-content-center align-items-center loading">
        <div className="loading-inner">
            <TailSpin color="#b2bec3" width={100} height={100}/>
            <img src={logo} alt="Loading UPshow" style={{ position: 'absolute', width: 60, top: 30, left: 20 }}/>
        </div>
    </div>;
};

const SmallLoading = () => {
    return <div className="small-loading">
        <div className="d-flex flex-column w-100 justify-content-center align-items-center wrapper">
            <div className="loading-inner">
                <TailSpin color="#b2bec3" width={50} height={50}/>
                <img src={logo} alt="Loading UPshow" style={{ position: 'absolute', width: 30, top: 15, left: 10 }}/>
            </div>
        </div>
    </div>;
};

const SuperSmallLoading = () => {
    return <div className="super-small-loading d-inline-block">
        <div className="d-flex flex-column justify-content-center align-items-center wrapper">
            <div className="loading-inner">
                <TailSpin color="#b2bec3" width={20} height={20}/>
                <img src={logo} alt="Loading UPshow" style={{ position: 'absolute', width: 10, top: 7, left: 5 }}/>
            </div>
        </div>
    </div>;
};

export { Loading, SmallLoading, SuperSmallLoading };

export default Loading;
