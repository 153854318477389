import { isDefined } from '../../helpers/utils';
export const training_status_validations = data => {
  const employees = (data === null || data === void 0 ? void 0 : data.page) || (data === null || data === void 0 ? void 0 : data.employees);
  if (!employees) return {
    error: 'No employees provided.'
  };
  if (employees.length === 0) return {
    error: 'Employees list is empty.'
  };
  return true;
};
export const customer_comments_validations = data => {
  const comments = data === null || data === void 0 ? void 0 : data.comments;
  if (!comments) return {
    error: 'No comments provided.'
  };
  if ((comments === null || comments === void 0 ? void 0 : comments.length) === 0) return {
    error: 'Comments list is empty.'
  };
  return true;
};
export const shift_logs_validations = data => {
  const shift_logs = data === null || data === void 0 ? void 0 : data.shift_logs;
  if (!shift_logs) return {
    error: 'No tasks provided.'
  };
  if (shift_logs.length === 0) return {
    error: 'Tasks list is empty.'
  };
  return true;
};
export const avg_ticket_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No top tickets provided.'
  };
  if (data.top.length === 0) return {
    error: 'Top ticket list is empty.'
  };
  return true;
};
export const car_count_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No top car count provided.'
  };
  if (data.top.length === 0) return {
    error: 'Car count list is empty.'
  };
  return true;
};
export const drive_thru_sos_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No top tickets provided.'
  };
  if (data.top.length === 0) return {
    error: 'Top ticket list is empty.'
  };
  return true;
};
export const store_speed_of_service = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No top tickets provided.'
  };
  if (data.top.length === 0) return {
    error: 'Top ticket list is empty.'
  };
  return true;
};
export const nps_validations = (data, metadata) => {
  if (!data) return {
    error: 'No data provided.'
  };
  const nps_data = data === null || data === void 0 ? void 0 : data.data;
  if (!nps_data) return {
    error: 'No nps data provided.'
  };
  const boh_nps = metadata === null || metadata === void 0 ? void 0 : metadata.boh_nps;
  if (!boh_nps) return {
    error: 'No nps metadata provided'
  };
  const data_found = boh_nps.map(metric => nps_data === null || nps_data === void 0 ? void 0 : nps_data[metric]).filter(m => !!m);
  if (data_found.length === 0) return {
    error: 'No nps data available.'
  };
  return true;
};
export const huddle_board_validations = (data, metadata) => {
  if (!(data !== null && data !== void 0 && data.data)) return {
    error: 'Missing data'
  };
  const {
    boh_huddleboard_entries = []
  } = metadata;
  let dataAvailable = false;
  for (const key of boh_huddleboard_entries) {
    if (!data.data[key]) return {
      error: `Missing key ${key}`
    };
    dataAvailable = dataAvailable || isDefined(data.data[key].value);
  }
  if (!dataAvailable) return {
    error: 'No data available'
  };
  return true;
};
export const employees_leaderboard_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.employees) return {
    error: 'No employees provided.'
  };
  if (data.employees.length === 0) return {
    error: 'Employees list is empty.'
  };
  return true;
};
export const locations_leaderboard_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No locations provided.'
  };
  if (data.top.length === 0) return {
    error: 'Locations list is empty.'
  };
  return true;
};
export const rewards_attachment_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.this_week) return {
    error: 'There is not information for the This week.'
  };
  if (!data.employees || data.employees.length < 1) return {
    error: 'Employees list is empty.'
  };
  return true;
};
export const daily_sales_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (data.sales === null || data.sales === undefined) return {
    error: 'No sales provided'
  };
  if (data.goal === null || data.goal === undefined) return {
    error: 'No goal provided'
  };
  return true;
};
export const loyalty_gauge_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (data.value === null || data.value === undefined) return {
    error: 'No Percentage provided'
  };
  if (data.goal === null || data.goal === undefined) return {
    error: 'No goal provided'
  };
  return true;
};
export const same_store_sales_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No store sales provided.'
  };
  if (data.top.length <= 1) return {
    error: 'Store Sales list is less than 2.'
  };
  return true;
};
export const traininig_store_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.top) return {
    error: 'No store sales provided.'
  };
  return true;
};
export const celebrate_validations = data => {
  if (!data) return {
    error: 'No data provided.'
  };
  if (!data.employees || !data.employees.length) return {
    error: 'No employees provided.'
  };
  return true;
};
export const celebrate_single_employee_validations = metadata => {
  if (!metadata) return {
    error: 'No metadata provided.'
  };
  if (!metadata.boh_employee) return {
    error: 'No employees provided.'
  };
  return true;
};
export const payday_validations = data => {
  if (!data || !data.pay_days || !data.pay_days.length) return {
    error: 'No paydays provided.'
  };
  return true;
};