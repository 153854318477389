import React from 'react';
import { Greenish, Greyish, Redish, Yellowish } from 'libs/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { gql } from '@apollo/client';

export function DeviceOnlineStatus ({ Device: { online, pending_installation, health }, only_icon = false }) {
    let iconColor, onlineStatus;

    if (online) {
        iconColor = Greenish;
        onlineStatus = 'online';
    } else if (pending_installation) {
        iconColor = Greyish;
        onlineStatus = 'pending installation';
    } else {
        iconColor = health === 'ERROR' ? Redish : Yellowish;
        onlineStatus = 'offline';
    }

    return <>
        <FontAwesomeIcon icon={faCircle} color={iconColor} size="xs" className="me-1" title={onlineStatus}/>
        {!only_icon && <small>{onlineStatus}</small>}
    </>;
}

export const DEVICE_ONLINE_STATUS_FRAGMENT = gql`fragment DeviceOnlineStatus on Device { id online health pending_installation socketOnline }`;