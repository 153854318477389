import React from 'react';
import IgFallbackBackground from '../Assets/social/ig_fallback_bg.png';
import TwFallbackBackground from '../Assets/social/tw_fallback_bg.png';
import ScFallbackBackground from '../Assets/social/sc_fallback_bg.png';
import { Card } from 'reactstrap';
import ReactPlayer from 'react-player';
import { Img } from 'react-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faQuestionCircle, faStopwatch, faTimesCircle as faTimesCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Moment from 'react-moment';
import { ContentLink } from 'libs/Links';
import IconButton from './IconButton';
import { DarkSlateBlue, Greenish, Grey2, Redish, WhiteTwo } from 'libs/css';

function Rating ({ content_rating }) {
    if (!content_rating?.length) return <FontAwesomeIcon color={Grey2} icon={faQuestionCircle} title="Unrated"/>;
    const unsafe = content_rating.some((cr) => cr.rating !== 1);

    if (unsafe) {
        return <FontAwesomeIcon color={Redish} icon={faTimesCircle} title="Unsafe"/>;
    } else {
        return <FontAwesomeIcon color={Greenish} icon={faCheckCircle} title="Safe"/>;
    }
}

export default function ContentPiece ({ content, compact, selected, isToggled, handleToggle, related }) {
    const { network, isExpired, thumbnail, url, id, expirationDate, type_str } = content;

    let networkIcon, imgFallback;
    switch (network) {
        case 'twitter':
            networkIcon = faTwitter;
            imgFallback = TwFallbackBackground;
            break;
        case 'instagram':
            networkIcon = faInstagram;
            imgFallback = IgFallbackBackground;
            break;
        case 'saycheese':
            networkIcon = faCamera;
            imgFallback = ScFallbackBackground;
            break;
        default:
            networkIcon = faQuestionCircle;
            imgFallback = ScFallbackBackground;
            break;
    }
    const imgSources = [thumbnail, imgFallback];

    return (
            <Card className={`content-piece overflow-hidden p-0 ${!compact && 'm-2'} ${selected && 'border-dark'}`}>
                <ContentLink content={content} className="position-relative" state={{ related }}>
                    {isExpired
                            ? <div className="expired-content d-flex flex-column justify-content-center align-items-center text-white h-100"
                                   style={{ backgroundImage: `url(${imgFallback})`, backgroundSize: 'cover' }}>
                                <FontAwesomeIcon icon={faStopwatch} size="lg" className="mb-2"/>
                                <span>EXPIRED</span>
                                {!!expirationDate && <Moment local format="MM/DD/yyyy" withTitle>{expirationDate}</Moment>}
                            </div>
                            : <>
                                {type_str === 'video'
                                        ? <div className="asset"><ReactPlayer url={url} width="100%" height="100%" muted controls={true}/></div>
                                        : <Img src={imgSources} alt="Thumbnail" top="true" style={{ objectFit: 'contain' }} className="asset d-block" id={id}/>}
                            </>
                    }
                </ContentLink>
                <div className="position-absolute w-100 p-2 d-flex justify-content-between">
                    <FontAwesomeIcon icon={networkIcon} className="colorful" size="lg"/>
                    <IconButton icon={isToggled ? faCheckCircle : faCircle} onClick={handleToggle} color={isToggled ? DarkSlateBlue : WhiteTwo} onHoverColor={DarkSlateBlue} size="lg"/>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between">
                    <Rating content_rating={content.content_rating}/>
                    {content.globally_banned && <FontAwesomeIcon icon={faTimesCircleSolid} color={Redish} title="Globally banned" className="ms-auto"/>}
                </div>
            </Card>
    );
}

