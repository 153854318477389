import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faBars, faBox, faBuilding, faChartBar, faCogs, faDatabase, faFlag, faList, faMusic, faPortrait, faQrcode, faSatelliteDish, faTags, faTent, faTh, faTv, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faWpforms, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { BrownishGrey, DarkSlateBlue } from 'libs/css';
import '../Styles/Menu.scss';
import { AppContext } from './Home';

import GraphQLLogo from '../Assets/GraphQLLogo';
import LiveEventsLogo from '../Assets/liveeventslogo';
import UPNowLogo from '../Assets/UPshowNowLogo';

const Menu = () => {
    const { user, expanded, setExpanded, isAllowed } = useContext(AppContext);
    const close = () => { setExpanded(false); };

    return (<div id="menu" className={expanded ? 'expanded' : 'contracted'}>
        <div className="flexGrowZero">
            {user && <ul className={`Menu ${expanded ? 'expanded' : 'collapsed'}`}>
                <li title="Organizations">
                    <NavLink to="/organization/dashboard" activeClassName="selected" onClick={close} isActive={(match, location) => location?.pathname.match(/^\/organization/)}>
                        <FontAwesomeIcon icon={faBuilding} fixedWidth color={BrownishGrey}/>
                        <span className="label">Organizations</span>
                    </NavLink>
                </li>

                <li title="Users">
                    <NavLink to="/users" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faUsers} fixedWidth color={BrownishGrey}/>
                        <span className="label">Users</span>
                    </NavLink>
                </li>

                <li title="Devices">
                    <NavLink to="/devices" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faSatelliteDish} fixedWidth color={BrownishGrey}/>
                        <span className="label">Devices</span>
                    </NavLink>
                </li>

                {isAllowed('ROLE_BACKEND_ANALYTICS') &&
                        <li title="Analytics">
                            <NavLink to="/analytics" activeClassName="selected" onClick={close}>
                                <FontAwesomeIcon icon={faChartBar} fixedWidth color={BrownishGrey}/>
                                <span className="label">Analytics</span>
                            </NavLink>
                        </li>}

                {isAllowed('ROLE_UI_VERSION_MASTER') &&
                        <li title="UI Versioning">
                            <NavLink to="/ui/loaders" activeClassName="selected" onClick={close}>
                                <FontAwesomeIcon icon={faCogs} fixedWidth color={BrownishGrey}/>
                                <span className="label">UI Versioning</span>
                            </NavLink>
                        </li>}

                <li title="UI Script Editing">
                    <NavLink to="/uiscript" activeClassName="selected">
                        <FontAwesomeIcon icon={faList} fixedWidth color={BrownishGrey}/>
                        <span className="label">UI Script Editing</span>
                    </NavLink>
                </li>
                {(isAllowed('ROLE_CS_TEAM') || isAllowed('ROLE_MEDIA_CHANNEL_MANAGER')) && <li title="Entertainment">
                    <NavLink to="/entertainment" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faTv} fixedWidth color={BrownishGrey}/>
                        <span className="label">Entertainment</span>
                    </NavLink>
                </li>}
                <li title="UPshowNow">
                    <NavLink to="/upshownow" activeClassName="selected" onClick={close}>
                        <UPNowLogo className="fa-fw"/>
                        <span className="label">UPshowNow</span>
                    </NavLink>
                </li>

                {(isAllowed('ROLE_CS_TEAM') || isAllowed('ROLE_MEDIA_CHANNEL_MANAGER')) &&
                        <li title="MediaChannel">
                            <NavLink to="/media_channel" activeClassName="selected" onClick={close}>
                                <FontAwesomeIcon icon={faYoutube} fixedWidth color={BrownishGrey}/>
                                <span className="label">MediaChannel</span>
                            </NavLink>
                        </li>}

                {(isAllowed('ROLE_MARKETING') || isAllowed('ROLE_ENGINEER')) &&
                        <li title="Banners">
                            <NavLink to="/banners" activeClassName="selected">
                                <FontAwesomeIcon icon={faFlag} fixedWidth color={BrownishGrey}/>
                                <span className="label">Banners</span>
                            </NavLink>
                        </li>}

                {(isAllowed('ROLE_FULFILLMENT_ORDER')) &&
                        <li title="Fulfillment Order">
                            <NavLink to="/fulfillment_order" activeClassName="selected">
                                <FontAwesomeIcon icon={faBox} fixedWidth color={BrownishGrey}/>
                                <span className="label">Fulfillment Order</span>
                            </NavLink>
                        </li>}

                {(isAllowed('ROLE_SALE_ORDER')) &&
                        <li title="Sales Orders">
                            <NavLink to="/sales_orders" activeClassName="selected">
                                <FontAwesomeIcon icon={faTags} fixedWidth color={BrownishGrey}/>
                                <span className="label">Sales Orders</span>
                            </NavLink>
                        </li>}

                {(isAllowed('ROLE_BOH_ALOHA') || isAllowed('ROLE_BOH_EPSILON') || isAllowed('ROLE_BOH_EXPLORER') || isAllowed('ROLE_ENGINEER')) &&
                        <li title="Back of House">
                            <NavLink to="/backofhouse" activeClassName="selected">
                                <FontAwesomeIcon icon={faDatabase} fixedWidth color={BrownishGrey}/>
                                <span className="label">Back of House</span>
                            </NavLink>
                        </li>}

                {isAllowed('ROLE_SURVEYS') &&
                        <li title="Surveys">
                            <NavLink to="/surveys" activeClassName="selected" onClick={close}>
                                <FontAwesomeIcon icon={faWpforms} fixedWidth color={BrownishGrey}/>
                                <span className="label">Employee Bio Surveys</span>
                            </NavLink>
                        </li>}

                <li title="Plugins">
                    <NavLink to="/plugins" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faTh} fixedWidth color={BrownishGrey}/>
                        <span className="label">Plugins</span>
                    </NavLink>
                </li>

                <li title="Social Content">
                    <NavLink to="/content" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faPortrait} fixedWidth color={BrownishGrey}/>
                        <span className="label">Social Content</span>
                    </NavLink>
                </li>

                {isAllowed('ROLE_BACKEND_LIVE_EVENT') && <li title="Live Events">
                    <NavLink to="/live_events" activeClassName="selected" onClick={close}>
                        <LiveEventsLogo/>
                        <span className="label">Live Events</span>
                    </NavLink>
                </li>}

                <li title="Premium Audio">
                    <NavLink to="/premium_audio" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faMusic} fixedWidth color={BrownishGrey}/>
                        <span className="label">Premium Audio</span>
                    </NavLink>
                </li>

                <li title="Tracking Codes">
                    <NavLink to="/tracking_codes" activeClassName="selected" onClick={close}>
                        <FontAwesomeIcon icon={faQrcode} fixedWidth color={BrownishGrey}/>
                        <span className="label">Tracking Codes</span>
                    </NavLink>
                </li>

                {isAllowed('ROLE_GRAPHQL') &&
                        <li title="GraphQL">
                            <NavLink to="/graphql" activeClassName="selected" onClick={close}>
                                <GraphQLLogo className="fa-fw"/>
                                <span className="label">GraphQL</span>
                            </NavLink>
                        </li>}

                {(isAllowed('ROLE_SMART_PAPER_REQUEST')) &&
                        <li title="Smart Paper">
                            <NavLink to="/smart_paper" activeClassName="selected">
                                <FontAwesomeIcon icon={faTent} fixedWidth color={BrownishGrey}/>
                                <span className="label">Smart Paper</span>
                            </NavLink>
                        </li>}
            </ul>}

            {/* Outside normal menu, it is the collapse option on the bottom */}
            <a className="manito open_menu_option text-decoration-none" href="#" onClick={() => setExpanded(!expanded)}>
                {!expanded && <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faBars} transform="shrink-5 left-4" color={BrownishGrey}/>
                    <FontAwesomeIcon icon={faArrowRight} transform="shrink-7 right-3" color={BrownishGrey}/>
                </span>}
                {expanded && <>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faArrowLeft} transform="shrink-7 left-3" color={DarkSlateBlue}/>
                        <FontAwesomeIcon icon={faBars} transform="shrink-5 right-4" color={DarkSlateBlue}/>
                    </span>
                    <span className="label">Collapse</span>
                </>}
            </a>
        </div>
    </div>);
};

export default withRouter(Menu);
