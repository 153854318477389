import React from 'react';
import { useQuery } from '@apollo/client';
import Select, { components } from 'react-select';

import { GET_BACKEND_GROUPS } from '../api/queries/backend';

const OptionComponent = (props) => {
    const { data } = props;

    return <components.Option {...props}>
        <div>
            {data.display_name}
            <br/>
            <small className="text-body-secondary">{data.description}</small>
        </div>
    </components.Option>;
};

const RoleGroupSelect = ({
    onChange,
    value = [],
    loading = false
}) => {
    const {
        data: roleGroupsData,
        loading: roleGroupsLoading
    } = useQuery(GET_BACKEND_GROUPS, { fetchPolicy: 'cache-and-network' });

    const groups = roleGroupsData ? roleGroupsData.backend.groups : [];

    const handleGroupChange = (selectedOptions) =>  onChange(selectedOptions ?? []);

    return (
        <>
            <Select
            value={value}
            onChange={handleGroupChange}
            options={groups}
            isLoading={loading || roleGroupsLoading}
            closeMenuOnSelect={false}
            placeholder={'Select Groups'}
            components={{ Option: OptionComponent }}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.display_name}
            isMulti/>
            {!value.length && <div className='ms-1 mt-2 text-primary text-body-secondary small'>User will be an Administrator.</div>}
        </>
    );
};

export default RoleGroupSelect;
