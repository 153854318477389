import React from 'react';

function ControlUi ({ className }) {
    return (<svg viewBox="0 0 50 50" version="1.1" className={ "fa-fw " + className }>
        <title>control_icon_logo</title>
        <g id="control_icon_logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M29,7.81878627 L25.5559758,4.42616667 C25.4165535,4.28819684 25.2262977,4.21052632 25.0277595,4.21052632 C24.8292213,4.21052632 24.6389655,4.28819684 24.4995432,4.42616667 L9.21877548,19.4794827 C8.92707484,19.7680334 8.92707484,20.2318294 9.21877548,20.5203801 L24.4995432,35.5745783 C24.6392066,35.7121082 24.8293721,35.7894737 25.0277595,35.7894737 C25.2261469,35.7894737 25.4163124,35.7121082 25.5559758,35.5745783 L29,32"
                id="Path" fill="#868686" fillRule="nonzero"></path>
            <path
                d="M28.8264119,31.661032 L25.6018431,34.9096373 C25.4710792,35.041295 25.2930311,35.1153571 25.1072851,35.1153571 C24.9215391,35.1153571 24.7434911,35.041295 24.6127271,34.9096373 L10.3056596,20.4982264 C10.0325463,20.2219964 10.0325463,19.7780036 10.3056596,19.5017736 L24.6127271,5.09120713 C24.7432653,4.95912836 24.9213979,4.88477423 25.1072851,4.88477423 C25.2931724,4.88477423 25.471305,4.95912836 25.6018431,5.09120713 L28.8264119,8.33896795 L35.7705307,1.34437541 C35.9944046,1.11931913 36.0614443,0.782329737 35.9406993,0.488986281 C35.8199544,0.195642825 35.5349114,0.003004963 35.2171672,0 L17.4714621,0 C17.2442245,-0.00104983307 17.0278687,0.097043515 16.8791771,0.268544082 L0.193662171,19.4815067 C-0.0645540569,19.779136 -0.0645540569,20.220864 0.193662171,20.5184933 L16.8834077,39.7314559 C17.0320993,39.9029565 17.2484551,40.0010498 17.4756927,40 L35.2171672,40 C35.5349114,39.996995 35.8199544,39.8043572 35.9406993,39.5110137 C36.0614443,39.2176703 35.9944046,38.8806809 35.7705307,38.6556246 L28.8264119,31.661032 Z"
                id="Path" fill="#8E8E8E" fillRule="nonzero"></path>
            <path d="M39.766933,19.42733 L28.5258935,8 L17.1960024,19.5185748 C16.9346659,19.7855387 16.9346659,20.2144613 17.1960024,20.4814252 L28.5258935,32 L28.5258935,32 L39.766933,20.573539 C40.077689,20.2555928 40.077689,19.7452762 39.766933,19.42733 Z" id="Path" fill="#AEAEAE" fillRule="nonzero"></path>
        </g>
    </svg>);
}
export default ControlUi;