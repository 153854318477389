import React, { cloneElement, useCallback, useContext, useState } from 'react';
import { AppContext } from 'Layout/Home';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { DELETE_DEVICE, FIX_CORRUPTED_UI, REBOOT_DEVICE, RENAME_DEVICE, SEND_COMMAND } from '../api/queries/screen';
import { genericMutationCompletedHelper, genericMutationErrorHandler } from 'libs/genericMutationHandler';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { v4 as uuid } from 'uuid';
import ConfirmDialog from './ConfirmDialog';
import { toast } from 'react-toastify';
import ControlUI from '../Assets/control-ui';

const REACT_APP_UI_LOGS_KIBANA = process.env.REACT_APP_UI_LOGS_KIBANA;

//TODO: How to get ELASTIC_INDEX on dev?
const ELASTIC_INDEX = `99ca8450-86f0-11e9-bd92-6183184defd5`;

function elasticUrl (screen_id) {
    return `${REACT_APP_UI_LOGS_KIBANA}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!f,value:5000),time:(from:now-15m,mode:quick,to:now))&_a=(columns:!(device_uptime,level,event),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${ELASTIC_INDEX}',key:screen_id,negate:!f,params:(query:'${screen_id}'),type:phrase,value:'${screen_id}'),query:(match:(screen_id:(query:'${screen_id}',type:phrase))))),index:'${ELASTIC_INDEX}',interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))`;
}

function RenameDevice ({ children, onDone = () => {}, device }) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(device.name);
    const [renameDevice] = useMutation(RENAME_DEVICE, {
        variables: { id: device.id },
        onError: genericMutationErrorHandler,
        onCompleted: ({ backendMutation: { mutationResult } }) => {
            if (genericMutationCompletedHelper(mutationResult)) {
                onDone();
                setIsOpen(false);
            }
        }
    });
    const handleSubmit = useCallback(() => renameDevice({ variables: { name } }), [name, renameDevice]);
    const Children = () => cloneElement(children, {
        onClick: (e) => {
            console.log(e);
            e.stopPropagation();
            setIsOpen(true);
        }
    });

    return <>
        <Children/>
        {isOpen && <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} returnFocusAfterClose={false}>
            <ModalHeader toggle={() => setIsOpen(false)}>Rename Device {device.name}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="rename--device">New Name</Label>
                        <InputGroup>
                            <Input type="text" name="name" id="rename--device" value={name} onChange={(e) => setName(e.target.value)}/>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="transparent" onClick={() => setIsOpen(false)}>Cancel</Button>
                <Button disabled={!name} color="primary" onClick={handleSubmit}>Submit</Button>
            </ModalFooter>
        </Modal>}
    </>;
}

export function ScreenActions ({ direction, Device: { id: device_id, name, model, serial, skykit, organization: { id: org_id } }, onRefresh = () => {} }) {
    const { isAllowed } = useContext(AppContext);
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [preventClose, setPreventClose] = useState(false); // TODO: This is a hack, do not replicate, fix it
    const [sendCommand] = useMutation(SEND_COMMAND, {
        variables: { device_id }, onError: genericMutationErrorHandler, onCompleted: (data) => {
            if (genericMutationCompletedHelper(data?.notify_screen)) {
                setPreventClose(false);
                setDropdownOpen(false);
            }
        }
    });
    const [rebootDevice] = useMutation(REBOOT_DEVICE,
        {
            variables: { device_id }, onError: genericMutationErrorHandler, onCompleted: (data) => {
                if (genericMutationCompletedHelper(data?.DeviceMutation?.reboot)) setDropdownOpen(false);
            }
        });
    const [deleteDevice] = useMutation(DELETE_DEVICE, {
        variables: { device_id }, onError: genericMutationErrorHandler, onCompleted: (data) => {
            if (genericMutationCompletedHelper(data?.backendMutation?.delete_device)) {
                onRefresh();
                setPreventClose(false);
                history.push(`/organization/${org_id}/screens`);
            }
        }
    });
    const [migrateAndroid] = useMutation(gql`mutation migrateAndroid($serial: String!) {
                backendMutation {
                    skykitAndroidAppMigrate(serial: $serial) {
                        successful message status
                    }
                }
            }`,
            {
                variables: { serial },
                onError: genericMutationErrorHandler,
                onCompleted: (data) => {
                    if (genericMutationCompletedHelper(data?.backendMutation.skykitAndroidAppMigrate)) {
                        setDropdownOpen(false);
                        onRefresh();
                    }
                }
            });
    const [fixCorruptedUI] = useMutation(FIX_CORRUPTED_UI, {
        variables: { deviceId: device_id },
        onError: () => toast.error('Please try again'),
        onCompleted: (data) => {
            if (data.backendMutation.fixCorruptedUI.successful) {
                toast.success('The new UI has been send');
            } else {
                toast.error(data.backendMutation.fixCorruptedUI.message);
            }
        }
    });

    const [inputCommand, setInputCommand] = useState('');
    const toggle = useCallback(() => {
        if (!preventClose) setDropdownOpen(prevState => !prevState);
    }, [preventClose]);

    return <>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-inline-block" direction={direction ?? 'down'}>
            <DropdownToggle color="transparent">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </DropdownToggle>
            <DropdownMenu className="pe-3">
                <DropdownItem href={elasticUrl(device_id)} target="_blank" className="text-decoration-none small text-nowrap">
                    <FontAwesomeIcon icon={faCloud} className="me-1"/> Logs
                </DropdownItem>
                {serial && ['ChromeOS', 'ChromeOS PWA'].includes(model) && <DropdownItem href={`https://admin.google.com/ac/chrome/devices/${serial}`} className="text-decoration-none small text-nowrap" target="_blank" rel="nofollow noreferrer">
                    <FontAwesomeIcon icon={faGoogle} className="me-1"/> Admin
                </DropdownItem>}
                {skykit?.deviceKey && ['Skykit', 'AndroidSkykit'].includes(model) && <DropdownItem href={`https://control-ui.skykit.com/devices/${skykit.deviceKey}`} className="text-decoration-none small text-nowrap" target="_blank" rel="nofollow noreferrer">
                    <ControlUI className="me-1"/> Control
                </DropdownItem>}
                <div className="dropdown-divider"/>
                <h6 className="dropdown-header">Support</h6>
                {isAllowed('ROLE_CS_TEAM') && ['ChromeOS'].includes(model) &&
                        <ConfirmDialog confirmAction={() => fixCorruptedUI()} destructive={true} message={`Use with caution, using this feature on a working device will cause issues`} confirmText="Yes, I do It">
                            <DropdownItem onMouseOut={() => setPreventClose(false)} onMouseOver={() => setPreventClose(true)}>Fix Corrupted UI</DropdownItem>
                        </ConfirmDialog>
                }
                <DropdownItem onClick={() => sendCommand({ variables: { command: 'identify', payload: { uuid: uuid() } } })}>Identify</DropdownItem>
                <DropdownItem onClick={() => sendCommand({ variables: { command: 'speed_test', payload: { uuid: uuid() } } })}>Speed Test</DropdownItem>
                <DropdownItem onClick={() => sendCommand({ variables: { command: 'advance_state', payload: { uuid: uuid() } } })}>Advance State</DropdownItem>
                {skykit?.deviceKey && model === 'Skykit' && <DropdownItem onClick={migrateAndroid} >Migrate To Android</DropdownItem>}

                {isAllowed('ROLE_ENGINEER') && <>
                    <div className="dropdown-divider"/>
                    <h6 className="dropdown-header">Cleanup</h6>
                    <DropdownItem onClick={() => sendCommand({ variables: { command: 'gc_cleanup' } })}>GC cleanup</DropdownItem>
                    <DropdownItem onClick={() => sendCommand({ variables: { command: 'cache_clear', payload: { uuid: uuid() } } })}>Clear LocalStorage</DropdownItem>
                    {model === 'ChromeOS' && <DropdownItem onClick={() => sendCommand({ variables: { command: 'clearteslacache', payload: { uuid: uuid() } } })}>Clear Tesla Cache</DropdownItem>}
                    {model !== 'ChromeOS' && <DropdownItem onClick={() => sendCommand({ variables: { command: 'clear_sw_cache', payload: { uuid: uuid() } } })}>Clear SW</DropdownItem>}
                    {model !== 'ChromeOS' && <DropdownItem onClick={() => sendCommand({ variables: { command: 'clear_browser_cache', payload: { uuid: uuid() } } })}>Clear Browser Cache</DropdownItem>}
                    <div className="dropdown-divider"/>
                    <h6 className="dropdown-header">Custom Command</h6>
                    <InputGroup className="ps-4">
                        <Input type="text" value={inputCommand} onChange={(evt) => setInputCommand(evt.target.value)} id={`custom-command-${device_id}`} bsSize="sm"/>
                        <Button onClick={() => sendCommand({ variables: { command: inputCommand } })} disabled={!inputCommand} size="sm">Send</Button>
                    </InputGroup>
                </>}

                <div className="dropdown-divider"/>
                <RenameDevice onDone={onRefresh} device={{ id: device_id, name }}><DropdownItem onMouseOut={() => setPreventClose(false)} onMouseOver={() => setPreventClose(true)}>Rename</DropdownItem></RenameDevice>
                <DropdownItem onClick={() => sendCommand({ variables: { command: 'restart' } })}>UI Reload</DropdownItem>
                {model === 'ChromeOS' && <DropdownItem onClick={() => sendCommand({ variables: { command: 'deponger' } })}>Reboot App</DropdownItem>}
                {(['ChromeOS', 'ChromeOS PWA'].includes(model) || ['Skykit', 'AndroidSkykit'].includes(model)) && <DropdownItem onClick={() => rebootDevice()}>Reboot Device</DropdownItem>}
                <ConfirmDialog confirmAction={() => deleteDevice()} destructive={true} message={`Do you want to delete "${name}"`} confirmText="Yes, Delete It">
                    <DropdownItem className="text-danger" onMouseOut={() => setPreventClose(false)} onMouseOver={() => setPreventClose(true)}>Delete Device</DropdownItem>
                </ConfirmDialog>
            </DropdownMenu>
        </Dropdown>
    </>;
}