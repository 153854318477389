import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import React, { useCallback } from 'react';
import { Col, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IconButton, SectionHeader } from 'Components';
import { faExclamationTriangle, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DarkSlateBlue, Greyish, Yellowish } from 'libs/css';
import { Helmet } from 'react-helmet';
import Loading, { SmallLoading } from './Loading';
import Error from './Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';

function toggle_path_helper (path, str) {
    return path.substring(0, path.lastIndexOf(str));
}

function AsModal ({ header, body, path, className, size, loading, error }) {
    const onTop = useRouteMatch({ path: '*' + path })?.isExact ?? false;
    const history = useHistory();
    const toggle = useCallback(() => history.push(toggle_path_helper(history.location.pathname, path)), [history, path]);
    const onTopClass = onTop ? ' d-block': 'd-none';
    return <Modal isOpen keyboard backdrop toggle={toggle} className={className + ' UPModal ' + onTopClass} size={size}>
        <ModalHeader>
            {error && <FontAwesomeIcon icon={faExclamationTriangle} color={Yellowish}/>}
            {!loading && !error && <>{isFunction(header) ? header({as: 'modal'}) : header}</>}
            <span className="modal-actions">
                <Link to={path}>
                    <IconButton icon={faExpand} color={Greyish} onHoverColor={DarkSlateBlue} size="xs"/>
                </Link>
                <IconButton className="close-modal-btn" icon={faTimes} color={Greyish} onHoverColor={DarkSlateBlue} size="xs" onClick={toggle}/>
            </span>
        </ModalHeader>
        <ModalBody>
            {loading && <SmallLoading/>}
            {error && <Error error={error}/>}
            {!loading && !error && <>{isFunction(body) ? body({as: 'modal'}) : body}</>}
        </ModalBody>
    </Modal>;
}

function AsPage ({ title, header, body, className, loading, error }) {
    return <Container fluid className={`${className ?? ''} ${(!!error || loading) && 'h-100'}`}>
        {title && <Helmet><title>{title}</title></Helmet>}
        {!error && !loading && <SectionHeader>
            <Col>
                <h3 className="d-flex align-items-center">{isFunction(header) ? header({as: 'page'}) : header}</h3>
            </Col>
        </SectionHeader>}

        {loading && <Loading/>}
        {error && <Error error={error}/>}
        {!loading && !error && <>{isFunction(body) ? body({as: 'page'}) : body}</>}

    </Container>;
}

export function PageOrModal (props) {
    const isPage = useRouteMatch({ path: props.path })?.isExact ?? false;

    if (isPage) {
        return <AsPage {...props}/>;
    } else {
        return <AsModal {...props}/>;
    }
}

export function UPLink ({ path, className, children, state }) {
    const history = useHistory();

    const clickHandler = (evt) => {
        if (!evt.metaKey) {
            evt.preventDefault();
            history.push(`${location.pathname}${path}`, state);
        }
    };

    return <Link to={path} onClick={clickHandler} className={className + ' text-decoration-none manito'}>
        {children}
    </Link>;
}
