import React from 'react';
import { Badge } from 'reactstrap';
import { gql } from '@apollo/client';

export const BADGE_FRAGMENT = gql`fragment BadgeAttributes on Organization { leaf root billing is_test is_active spotlight_only id SalesforceMetadata { pilotLocation } }`;

export function OrganizationBadge ({ organization, className }) {
    const badges = [];

    if (organization.leaf) {
        badges.push({ color: 'success', type: 'LEAF' });
    }

    if (organization.root) {
        badges.push({ color: 'primary', type: 'ROOT' });
    }

    if (!organization.leaf && !organization.root) {
        badges.push({ color: 'secondary', type: 'REGULAR' });
    }

    if (organization.billing) {
        badges.push({ color: 'info', type: 'BILLING' });
    }

    if (organization.is_test) {
        badges.push({ color: 'info', type: 'TEST' });
    }

    if (organization.is_active === false) {
        badges.push({ color: 'danger', type: 'INACTIVE' });
    }

    if (organization.spotlight_only) {
        badges.push({ color: 'warning', type: 'SPOTLIGHT ONLY' });
    }

    if (organization.SalesforceMetadata?.pilotLocation) {
        badges.push({ color: 'upshow', type: 'PILOT' });
    }

    return badges.map((badge) => <Badge key={`badge-${organization.id}-${badge.type}`} color={badge.color} pill className={className}>{badge.type}</Badge>);
}
