import * as boh_validations from './BOH/helpers/boh_validations';
export default function validate(spotlight) {
  // Checks for BOH Spotlights
  const isBohSpotlight = !!spotlight.properties.find(p => p === 'boh_spotlight');
  if (isBohSpotlight) {
    const boh_type = spotlight.properties.find(p => p === null || p === void 0 ? void 0 : p.startsWith('boh_type_'));
    let check = true;
    switch (boh_type) {
      case 'boh_type_training_course':
        check = boh_validations.training_status_validations(spotlight.boh_data);
        break;
      case 'boh_type_location_avg_ticket':
        check = boh_validations.avg_ticket_validations(spotlight.boh_data);
        break;
      case 'boh_type_customer_comments':
        check = boh_validations.customer_comments_validations(spotlight.boh_data);
        break;
      case 'boh_type_shift_log':
        check = boh_validations.shift_logs_validations(spotlight.boh_data);
        break;
      case 'boh_type_car_count':
        check = boh_validations.car_count_validations(spotlight.boh_data);
        break;
      case 'boh_type_nps':
        check = boh_validations.nps_validations(spotlight.boh_data, spotlight.metadata);
        break;
      case 'boh_type_drive_thru_sos':
        check = boh_validations.drive_thru_sos_validations(spotlight.boh_data);
        break;
      case 'boh_type_store_speed_of_service':
        check = boh_validations.store_speed_of_service(spotlight.boh_data);
        break;
      case 'boh_type_locations_sales_growth':
        // apparently this has no checks
        break;
      case 'boh_type_huddle_board':
        check = boh_validations.huddle_board_validations(spotlight.boh_data, spotlight.metadata);
        break;
      case 'boh_type_avg_check_size':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;
      case 'boh_type_product_sales_leaderboard':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;
      case 'boh_type_avg_guest_spend':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;
      case 'boh_type_thermometer_sales':
        check = boh_validations.daily_sales_validations(spotlight.boh_data);
        break;
      case 'boh_type_percentage_to_goal':
        check = boh_validations.daily_sales_validations(spotlight.boh_data);
        break;
      case 'boh_type_loyalty_gauge':
        check = boh_validations.loyalty_gauge_validations(spotlight.boh_data);
        break;
      case 'boh_type_same_store_sales':
        check = boh_validations.same_store_sales_validations(spotlight.boh_data);
        break;
      case 'boh_type_product_sales_by_store':
      case 'boh_type_blazin_by_store':
        check = boh_validations.locations_leaderboard_validations(spotlight.boh_data);
        break;
      case 'boh_type_employee_highlight':
      case 'boh_type_bio':
      case 'boh_type_employee_shoutout':
      case 'boh_type_huddle_notes':
      case 'boh_type_charity':
      case 'boh_type_cleaning':
      case 'boh_type_product_highlight':
        break;
      case 'boh_type_payday':
        check = boh_validations.payday_validations(spotlight.boh_data);
        break;
      case 'boh_type_blazin_attachment':
        check = boh_validations.rewards_attachment_validations(spotlight.boh_data);
        break;
      case 'boh_type_training_by_store':
        check = boh_validations.traininig_store_validations(spotlight.boh_data);
        break;
      case 'boh_type_birthday':
      case 'boh_type_anniversary':
        check = boh_validations.celebrate_validations(spotlight.boh_data);
        break;
      case 'boh_type_birthday_single_employee':
      case 'boh_type_anniversary_single_employee':
        check = boh_validations.celebrate_single_employee_validations(spotlight.metadata);
        break;
      case 'boh_type_employee_loyalty_leaderboard':
        check = boh_validations.employees_leaderboard_validations(spotlight.boh_data);
        break;
      default:
        return {
          error: `Invalid boh_type ${boh_type}`
        };
    }
    if (check !== true) return check;
  }
  const customLayoutType = spotlight.properties.find(p => p.startsWith('layout_type_'));
  if (customLayoutType === 'layout_type_sidebar') {
    const {
      metadata: {
        custom_layout = {}
      }
    } = spotlight;
    const {
      main_image,
      sidebar_image
    } = custom_layout;
    if (!main_image || !sidebar_image) return {
      error: 'layout_type_sidebar needs to have two images'
    };
  }

  // By default every spotlight is valid
  return true;
}