import React, { useCallback, useContext, useState } from 'react';
import SearchBar from './SearchBar';
import * as Sentry from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronRight, faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { BrownishGrey, DarkSkyBlue, DarkSlateBlue, Redish } from 'libs/css';
import logo from '../Styles/UPshow_badge_primary.svg';
import { TailSpin } from 'react-loader-spinner';
import { AppContext } from './Home';
import { Badge, Button, Popover, PopoverBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AvatarUploader, IconButton } from 'Components';
import { AuthContext } from '@upshow/auth';
import stringifyError from 'libs/stringifyError';
import { gql, useMutation, useQuery } from '@apollo/client';

import '../Styles/Header.scss';
import Moment from "react-moment";
import { SmallLoading } from "./Loading";
import { MARK_NOTIFICATIONS_READ } from 'api/queries/user';
import { NotificationReadStatus } from "../IndividualPages/Notifications";

const GET_POPOVER_NOTIFICATIONS = gql`
    query getPopoverNotifications{
        user: me {
            id
            unreadNotifications
            Notifications(last: 25) {
                edges {
                    node {
                        id
                        message
                        createdAt
                        dismissed
                    }
                }
            }
        }
    }
`;

function NotificationsPopover () {
    const {data, loading, refetch} = useQuery(GET_POPOVER_NOTIFICATIONS, {fetchPolicy: 'cache-and-network'});
    const [markNotificationsRead] = useMutation(MARK_NOTIFICATIONS_READ, {onCompleted: refetch});

    if (!data && loading) return <div><SmallLoading/></div>;

    return <div className="d-flex flex-column">
        <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex flex-nowrap gap-1 h-fit justify-content-between w-100'>
                <h6 className='my-1'>Notifications
                    {data.user.unreadNotifications > 0 && <Badge color="danger" className="ms-1 p-1" style={{fontSize: "0.65rem"}}>{data.user.unreadNotifications > 99 ? "99+" : data.user.unreadNotifications}</Badge>}
                </h6>
                {data.user.Notifications.edges.length !== 0 && <Button onClick={() => markNotificationsRead({variables: {user_id: data.user.id}})} color='primary' size='sm' disabled={data.user.unreadNotifications === 0}>Mark all as read</Button>}
            </div>
        </div>

        <div className="my-2 overflow-scroll" style={{maxHeight: 200}}>
            {data.user.Notifications.edges.length > 0 ? (
                    data.user.Notifications.edges.map(({node: notification}) => (
                            <div className='d-flex align-items-center border-bottom py-2 gap-2' key={notification.id}>
                                <NotificationReadStatus notification={notification} refetch={refetch}/>
                                <div className='d-flex flex-column small overflow-hidden'>
                                    <span className="single-line-ellipsis mb-1">{notification.message}</span>
                                    <span className='text-body-secondary mb-1'><Moment local withTitle format="LLL">{notification.createdAt}</Moment></span>
                                </div>
                            </div>
                    ))
            ) : (<p>No new notifications available at this time.</p>)}
        </div>

        <div className='pt-2 text-center small'>
            <a href="/notifications">View all notifications <FontAwesomeIcon icon={faChevronRight} size="xs"/></a>
        </div>
    </div>
}


const Header = () => {
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
    const history = useHistory();

    const onSelect = useCallback((result) => {
        switch (result.type) {
            case 'User':
                history.push(`/users/${result.id}`);
                break;
            case 'Account':
                history.push(`/organization/${result.organization.id}`);
                break;
            case 'Organization':
                history.push(`/organization/${result.id}`);
                break;
            case 'Device':
                history.push(`/screen-${result.id}`);
                break;
            default:
                alert('Something is broken :(');
        }
    }, []);

    const {version, user, loading, error, expanded, setExpanded} = useContext(AppContext);
    const {oktaAuth} = useContext(AuthContext);

    return (<>
        <div id="header">
            <div onClick={() => setExpanded(!expanded)} className="open_mobile_menu manito">
                <FontAwesomeIcon icon={faBars} color={BrownishGrey} fixedWidth/>
            </div>
            <div className="logo">
                <img src={logo} alt="UP"/>
            </div>
            {user && <SearchBar className="search_bar" onChange={onSelect} placeholder="Navigate to" autoclean/>}

            <div className="right-side d-flex">
                <div id="openNotificationsMenu">
                    <IconButton
                            icon={faBell}
                            onClick={() => setOpenNotificationsMenu(!openUserMenu)}
                            size="xl"
                            color={DarkSlateBlue}
                            className="px-2"
                            text={user?.unreadNotifications > 0 && <FontAwesomeIcon icon={faCircle} color={Redish} style={{position: "absolute", left: 25, top: 12}} size="xs"/>}
                    />
                </div>
                <div className="user" id="openUserMenu" onClick={() => setOpenUserMenu(!openUserMenu)}>
                    {user && <img src={user.avatar_url} alt={user.username} className="user-avatar"/>}
                    {loading && <div className="user-loading"><TailSpin color={DarkSkyBlue} width={30} height={30}/></div>}
                    {error && <div className="user-error" title={stringifyError(error)}><FontAwesomeIcon icon={faExclamationCircle} color={Redish}/></div>}
                </div>
            </div>
        </div>
        {user && <Popover className="userMenu" trigger="legacy" placement="bottom" isOpen={openUserMenu} target="openUserMenu" toggle={() => setOpenUserMenu(false)}>
            <PopoverBody className="d-flex flex-row align-items-center">
                <AvatarUploader user={user}>
                    <img className="user_avatar" src={user.avatar_url} alt={user.username}/>
                </AvatarUploader>
                <div className="ms-2 d-flex flex-column ">
                    <b>{user.username}</b>
                    <small>{user.email}</small>
                    <small>version: {version}</small><br/>
                    <div className="d-flex flex-row">
                        <Button size="sm" onClick={() => history.push('/users/' + user.id)}>
                            Profile
                        </Button>
                        &nbsp;
                        <Button size="sm" onClick={() => {
                            oktaAuth.signOut();
                            Sentry.configureScope(scope => scope.setUser(null));
                        }}>
                            Logout
                        </Button>
                    </div>
                </div>
            </PopoverBody>
        </Popover>}
        <Popover className="notifications_menu" trigger="legacy" placement="bottom-end" isOpen={openNotificationsMenu} target="openNotificationsMenu" toggle={() => setOpenNotificationsMenu(false)}>
            <PopoverBody style={{width: 300}}>
                <NotificationsPopover/>
            </PopoverBody>
        </Popover>
    </>);
};

export default Header;
