import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

export const IconButton = ({ id, onClick, icon, color = 'black', disabled = false, onHoverColor, tooltip, placement, spin, className, text, title, size, name, type = 'button' }) => {
    const ref = useRef();
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (disabled && hover) setHover(false);
    }, [disabled, hover]);

    return (<>
        <button
            id={id}
            ref={ref}
            name={name}
            onMouseEnter={() => setHover(!disabled)}
            onMouseLeave={() => setHover(false)}
            onFocus={() => setHover(!disabled)}
            onBlur={() => setHover(false)}
            onClick={disabled ? (evt) => {
                console.log(evt);
            } : onClick}
            className={`pointer-button ${className}`}
            disabled={disabled}
            title={title}
            type={type}
        >
            <FontAwesomeIcon
                icon={icon} color={!disabled && hover ? (onHoverColor ?? color) : color} fixedWidth
                style={{ opacity: disabled ? 0.5 : 1 }} spin={spin} className={text ? 'me-1' : ''} size={size}
            />
            {text}
        </button>
        {tooltip && <UncontrolledTooltip placement={placement} target={ref} isOpen={hover}>{tooltip}</UncontrolledTooltip>}
    </>);
};

export default IconButton;
