import React, { cloneElement, useEffect, useRef, useState } from 'react';

import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import WarningIcon from '../Assets/warning-modal.svg';
import { Redish } from 'libs/css';

export const ConfirmDialog = ({
    skip = false, // Skip this entirely and just do the confirmAction, basically a way to just throw this conditionally
    destructive = false,
    title = 'Are you sure?', message = '',
    confirmText = 'Confirm', confirmColor, confirmTextColor, confirmAction = () => {},
    cancelText = 'Cancel', cancelColor, cancelTextColor, cancelAction = () => {},
    closedAction = () => {},
    children
}) => {
    const [modal, setModal] = useState(false);
    const cancelRef = useRef(null);
    const confirmRef = useRef(null);

    useEffect(() => {
        if (!modal) return;
        setTimeout(() => {
            if (destructive) {
                cancelRef.current?.focus();
            } else {
                confirmRef.current?.focus();
            }
        }, 0)
    }, [destructive, modal, cancelRef.current, confirmRef.current])

    const close = () => setModal(false);

    function handleClosed () {
        if (typeof closedAction === 'function') closedAction();
        close();
    }

    function handleKeyUp(func) { // Handles if the user uses enter or space to _click_ the button
        return (evt) => {
            if (evt.code === 'Space' || evt.code === 'Enter') {
                func();
            }
        }
    } // Side note: we are in 2022, why do we need to implement this ????

    function handleCancel () {
        if (typeof cancelAction === 'function') cancelAction();
        close();
    }

    const CancelButton = () => <Button color={cancelColor ?? (destructive ? 'primary' : 'link')} onClick={handleCancel} style={{ color: cancelTextColor }} autoFocus={destructive} onKeyUp={handleKeyUp(handleCancel)} innerRef={cancelRef} >
        {cancelText}
    </Button>;

    const handleConfirm = () => {
        if (typeof confirmAction === 'function') confirmAction();
        close();
    };

    const ConfirmButton = () => <Button className="confirmButton" color={confirmColor ?? (destructive ? 'link' : 'primary')} onClick={handleConfirm} style={{ color: (confirmTextColor ?? destructive ? Redish : null) }} autoFocus={!destructive} onKeyUp={handleKeyUp(handleConfirm)} innerRef={confirmRef}>
        {confirmText}
    </Button>;

    const Children = () => cloneElement(children, {
        onClick: (e) => {
            e.stopPropagation();
            if (skip) {
                if (typeof confirmAction === 'function') confirmAction();
                return null;
            } else {
                setModal(true);
            }
        }
    });

    return <>
        <Children/>
        <Modal isOpen={modal} toggle={close} returnFocusAfterClose={false} zIndex={1053} onClosed={handleClosed}>
            <ModalBody>
                <Col>
                    <FontAwesomeIcon
                        className='position-absolute top-0 right-0 p-3'
                        style={{ cursor: 'pointer' }}
                        icon={faTimes}
                        onClick={close}
                    />
                    <Row className="d-flex justify-content-center">
                        <Col sm={9} className="mt-4 d-flex flex-column align-items-center">
                            <img src={WarningIcon} alt="Warning Icon" className="mb-2"/>
                            <div className="m-0 mt-4 font-weight-bold text-center text-break" style={{ fontSize: 18, color: '#424242' }}>{title}</div>
                            {message && (<span className="mt-2 text-center text-break" style={{ fontSize: 14, color: '#424242' }}>{message}</span>)}
                        </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-center">
                        <Col sm={9} className="d-flex flex-column">
                            {destructive
                                ? <><CancelButton/><ConfirmButton/></>
                                : <><ConfirmButton/><CancelButton/></>
                            }
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    </>;
};

export default ConfirmDialog;
