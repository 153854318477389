import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Select, { components } from 'react-select';
import { faBuilding, faEnvelope, faTv, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailSpin } from 'react-loader-spinner';
import { BADGE_FRAGMENT, DeviceModelBadge, DeviceOnlineStatus, OrganizationBadge, OrganizationsPath } from 'Components';
import { Badge } from 'reactstrap';

const SEARCH = gql`
    query backendSearch($query: String!, $only: [String!]){
        backend {
            id
            search(query: $query, only: $only) {
                type: __typename
                ...on Organization { id text: name subtext: full_path parent_organizations { id name } account_name ...BadgeAttributes }
                ...on User { id text: username email subtext: email img: avatar_url enabled }
                ...on Device { id text: name subtext: serial model version appVersion online health pending_installation }
            }
        }
    }
${BADGE_FRAGMENT}
`;

const iconStyle = { marginRight: '20px', width: '35px', height: '35px', objectFit: 'scale-down' };

const Option = props => {
    const { data } = props;

    let subtext;
    let image;
    let text = data.text;

    switch (data.type) {
        case 'Device':
            image = <FontAwesomeIcon icon={faTv} style={iconStyle} alt={data.type}/>;
            subtext = <small className="result-item-device">
                <DeviceOnlineStatus Device={data} only_icon/>
                {data.model && <DeviceModelBadge model={data.model} className="mx-1"/>}
                {data.appVersion && <Badge title="App Version" className="me-1" pill>{data.appVersion}</Badge>}
                {data.version && <Badge title="UI Version" className="me-1" pill>{data.version}</Badge>}
                <br/>
                <i className="text-body-secondary">{data.subtext}</i>
            </small>;
            break;
        case 'User':
            image = <FontAwesomeIcon icon={faUser} style={iconStyle} alt={data.type}/>;
            subtext = <small className="result-item-user">
                <FontAwesomeIcon icon={faEnvelope}/> {data.email}
                {!data.enabled && <Badge title="Disabled" className="ms-1" pill color="danger">Disabled</Badge>}
            </small>;
            break;
        case 'Organization':
            image = <FontAwesomeIcon icon={faBuilding} style={iconStyle} alt={data.type}/>;
            if (data.account_name && data.text !== data.account_name) {
                text = <>{data.text} <small className="small text-body-secondary">({data.account_name})</small></>;
            }
            subtext = <small className="result-item-organization">
                <OrganizationBadge organization={data} className="me-1"/>
                <i className="text-body-secondary"><OrganizationsPath organizations={data.parent_organizations}/></i>
            </small>;
            break;

        default:
            subtext = <i className="result-item-generic">{data.subtext}</i>;
    }

    if (data.img) {
        image = <img src={data.img} alt={data.text} style={iconStyle}/>;
    }

    return (<components.Option {...props}>
        <div className="d-flex flex-row align-items-center" title={data.type}>
            {image}
            <div>
                <div>
                    <strong>{text}</strong>
                </div>
                <div>
                    {subtext}
                </div>
            </div>
        </div>
    </components.Option>);
};
const LoadingIndicator = () => {
    return <TailSpin color="#3c8dbc" width={35} height={25}/>;
};

const SearchBar = (props) => {
    const [value, setValue] = useState(props.value);
    const [searchQuery, setSearchQuery] = useState();
    const [searchResult, setSearchResult] = useState([]);
    const [search, { data, loading }] = useLazyQuery(SEARCH, {
        variables: { only: props.only },
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
    });
    useEffect(() => {
        const results = JSON.parse(JSON.stringify(data?.backend.search ?? [])).map(result => {
            result.label = result.text;
            result.value = `${result.type}_${result.id}`;
            return result;
        });
        setSearchResult(results);
    }, [data]);

    useEffect(() => {
        searchQuery && search({ variables: { query: searchQuery } });
    }, [searchQuery]);

    return <Select
            id={props.id ?? 'search-bar'}
            autoFocus={props.autoFocus || false}
            placeholder={props.placeholder}
            isClearable={props.isClearable}
            isDisabled={props.isDisabled}
            cacheOptions defaultOptions isSearchable
            theme={theme => ({ ...theme, borderRadius: 0 })}
            onInputChange={(inputValue) => {
                if (inputValue === '') {
                    return setSearchResult([]);
                }
                setSearchQuery(inputValue);
            }}
            options={searchResult}
            isLoading={loading} loadingMessage={() => 'Searching...'}
            components={{ Option, LoadingIndicator }}
            onChange={(v) => {
                props.onChange(v);
                setValue(props.autoclean ? null : v);
            }}
            onBlur={props.onBlur}
            className={props.className} style={props.style}
            filterOption={() => true}
            value={value}
    />;
};

export default SearchBar;
