import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const CustomModal = (props) => {
    const {headerProps = {}, footerProps = {}, footer, children, title, ...rest} = props;
    const {toggle} = rest;

    return (
        <Modal {...rest}>
            <ModalHeader toggle={toggle} {...headerProps}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {footer && <ModalFooter {...footerProps}>{footer}</ModalFooter>}
        </Modal>
    )
};

export default CustomModal;
