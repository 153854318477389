import React, { useState } from 'react';
import { TailSpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DarkSlateBlue, Redish } from "libs/css";
import ButtonS3Uploader from "./ButtonS3Uploader";
import { STATIC_CDN_PATH } from "../constants";


const AssetUploader = ({ file, setFile, required, path, accept, isUploading }) => {
    const [uploading, setUploading] = useState(false);

    return <>
        {uploading && <TailSpin color='#b2bec3' width={25} height={25} />}
        {file && <>
            <FontAwesomeIcon icon={faTrash} fixedWidth color={Redish} onClick={() => setFile(null)} />
            <a href={file} download target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faFileDownload} fixedWidth color={DarkSlateBlue} /></a>
        </>}

        {!file && <ButtonS3Uploader
                className='button-up'
                loading={false}
                onFinish={(result) => {
                    isUploading && isUploading([path, false]);
                    setUploading(false);
                    setFile(result.fileUrl);
                }}
                onStart={() => {
                    isUploading && isUploading([path, true]);
                    setUploading(true);
                }}
                s3UrlImage={STATIC_CDN_PATH}
                accept={accept}
                isRequired={required}
                path={path}
        />}
    </>;
};
export default AssetUploader
