import React from 'react';
import { Badge } from 'reactstrap';

export function DeviceModelBadge ({ model, className }) {
    let color;
    switch (model) {
        case 'ChromeOS':
        case 'ChromeOS PWA':
            color = 'success';
            break;
        case 'Skykit':
        case 'AndroidSkykit':
            color = 'info';
            break;
        case 'WebView':
            color = 'danger';
            break;
        case 'BrightSign':
            color = 'warning';
            break;
        case 'AFTS':
        case 'AFTM':
        case 'AFTT':
        case 'AFTRS':
        case 'AFTB':
            color = 'info';
            break;
        default:
            color = 'primary';
    }
    return <Badge title="Model" className={className} pill color={color}>{model}</Badge>;
}