import { useMutation, useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import Moment from "react-moment";
import { Button, Col, Table } from "reactstrap";

import { IconButton, InlineSwitch, SectionHeader } from "Components";
import { GET_NOTIFICATIONS, MARK_NOTIFICATIONS_READ, UPDATE_NOTIFICATION } from "api/queries/user";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { DarkSlateBlue, Greyish } from "../libs/css";
import { AppContext } from 'Layout/Home';

const ALL_NOTIFICATIONS = null;
const ONLY_UNREAD = false;

export const NotificationReadStatus = ({notification: {id, dismissed}, refetch}) => {
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION, { onCompleted: refetch });
    const {user: {id: user_id}} = useContext(AppContext)

    return <IconButton
            icon={faCircle}
            onClick={() => updateNotification({variables: {notification_id: id, user_id, dismissed: !dismissed}})}
            color={dismissed ? Greyish : DarkSlateBlue}
            tooltip={`Mark as ${dismissed ? "unread" : "read"}`}
            size="xs"
    />
}

export default function Notifications () {
    const [onlyUnread, setOnlyUnread] = useState(true)
    const {data, fetchMore, refetch} = useQuery(GET_NOTIFICATIONS, {fetchPolicy: 'cache-and-network', pollInterval: 300_000, variables: {dismissed: onlyUnread ? ONLY_UNREAD : ALL_NOTIFICATIONS}});
    const [markNotificationsRead] = useMutation(MARK_NOTIFICATIONS_READ, {onCompleted: refetch});

    const hasUnread = data?.user.unreadNotifications !== 0 ?? false;

    return (
            <div className="p-3">
                <SectionHeader title="Notifications">
                    <Col className="text-end">
                        <InlineSwitch label="Only show unread" defaultChecked={onlyUnread} name="only-show-unread" onChange={() => setOnlyUnread(!onlyUnread)}/>
                        <Button onClick={() => markNotificationsRead({variables: {user_id: data.user.id}})} color='primary' disabled={!hasUnread}>Mark all as read</Button>
                    </Col>
                </SectionHeader>
                {data && data.user.Notifications.edges.length > 0 ? (
                        <>
                            <Table responsive bordered className="small">
                                <thead>
                                <tr>
                                    <th className="w-75">Message</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.user.Notifications.edges.map(({node: notification}) => (
                                        <tr key={notification.id}>
                                            <td className={`d-flex gap-2 align-items-center ${notification.dismissed ? "bg-light" : ""}`}>
                                                <NotificationReadStatus notification={notification} refetch={refetch}/>
                                                <span className="mb-0" style={{maxWidth: "1000px", wordBreak: 'break-word'}}>
                                                    {notification.message}
                                                </span>
                                            </td>
                                            <td className={`${notification.dismissed ? "bg-light" : ""}`}>
                                                <Moment local withTitle format="LLL">{notification.createdAt}</Moment>
                                            </td>
                                        </tr>
                                ))}
                                </tbody>
                            </Table>
                            <div className="d-flex align-items-center justify-content-center">
                                {data.user.Notifications.pageInfo.hasNextPage && (
                                        <Button
                                                onClick={() =>
                                                        fetchMore({
                                                            variables: {
                                                                cursor: data.user.Notifications.pageInfo.endCursor,
                                                            },
                                                        })
                                                }
                                        >
                                            Load more
                                        </Button>
                                )}
                            </div>
                        </>
                ) : (
                        "No new notifications at this time."
                )}
            </div>
    );
}
