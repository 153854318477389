import React from 'react';
import { Greenish, Redish } from 'libs/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export function DeviceSocketStatus ({ Device: { socketOnline }, only_icon = false }) {
    let iconColor, onlineStatus;

    if (socketOnline) {
        iconColor = Greenish;
        onlineStatus = 'online';
    } else {
        iconColor = Redish;
        onlineStatus = 'offline';
    }

    return <>
        <FontAwesomeIcon icon={faCircle} color={iconColor} size="xs" className="me-1" title={onlineStatus}/>
        {!only_icon && <small>{onlineStatus}</small>}
    </>;
}