const WhiteTwo = '#f8f8f8';
const DarkSkyBlue = '#3aace2';
const DarkSlateBlue = '#21445e';
const BrownishGrey = '#676767';
const BrownishGrey50 = '#67676780';
const BrownishGreyTwo = '#707070';
const Greyish = '#d0d0d0';
const Redish = '#dc3b3b';
const Greenish = '#00b894';
const Yellowish = '#ffc107';
const Orangeish = '#db7d25';

const Black = '#000000';
const White = '#FFFFFF';
const Navy = '#060D33';
const Indigo = '#192CF8';
const Magenta = '#DB39FF';
const Blue = '#18B0F4';
const Aquamarine = '#04EE9C';
const Grey = '#CCCCCC';
const Grey2 = '#747474';

export {
    WhiteTwo,
    DarkSkyBlue, DarkSlateBlue,
    BrownishGrey, BrownishGrey50, BrownishGreyTwo,
    Redish, Greenish, Yellowish, Greyish, Orangeish,
    Black, White, Navy, Indigo, Magenta, Blue, Aquamarine, Grey, Grey2
};
