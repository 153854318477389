import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const NoMatchUrl = () => {
    const location = useLocation()
    return <div className='d-flex flex-column w-100 justify-content-center align-items-center h-100'>
        <h3>404</h3>
        <Card>
            <CardBody>
                The page you are trying to load does not seems to exist. <br />
                {location.pathname !== '/404' && <>
                    <center className='my-3'><small><i>{location.pathname}</i></small><br /></center>
                    Try reloading, if the problem persists please report it.
                </>}
            </CardBody>
        </Card>
    </div>;
};

export default NoMatchUrl;
