import { SuperSmallLoading } from 'Layout/Loading';
import { Button } from 'reactstrap';
import React from 'react';

export const TableFooter = (props) => {
    const { loading, hasNextPage, isEmpty, onLoadMore } = props;

    if (loading) return <span><SuperSmallLoading/></span>;

    return (
        hasNextPage
            ? <Button onClick={onLoadMore} size="sm" color="primary" disabled={loading}>Load more</Button>
            : <small className="text-body-secondary">{isEmpty ? 'No results' : 'No more results'}</small>
    );
};