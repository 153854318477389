async function loadCustomFont(font) {
  const fonts = [...document.fonts];
  if (!fonts.some(f => f.family === font.name)) {
    const newFont = new FontFace(font.name, `url(${font.url})`);
    await newFont.load();
    document.fonts.add(newFont);
  }
  return font.name;
}
export const loadCustomFonts = async (fonts, log) => {
  for (const font of fonts) {
    try {
      await loadCustomFont(font);
    } catch (error) {
      log.error(['SpotlightState', 'SpotlightPlayer'], `Error loading fonts. Fonts: ${JSON.stringify(font)}, error: ${error}`);
    }
  }
};