export const BOH_GOALS_SPEED_OF_SERVICE = 'boh.goals.speed_of_service';
export const BOH_GOALS_ALL_COMPLAINTS_RATIO = 'boh.goals.acr';
export const BOH_GOALS_OVERALL_SATISFACTION = 'boh.goals.overall_satisfaction';
export const BOH_GOALS_ORDER_ACCURACY = 'boh.goals.order_accuracy';
export const BOH_GOALS_LIKELIHOOD_TO_RETURN = 'boh.goals.likelihood_to_return';
export const BOH_GOALS_SPEED_OF_SERVICE_SCORE = 'boh.goals.speed_of_service_score';

export const BOH_GOALS_LOYALTY_IS_ALL_DAYS = 'boh.goals.loyalty_is_all_days_flag';
export const BOH_GOALS_LOYALTY_ALL_DAYS = 'boh.goals.loyalty_all_days';

export const BOH_GOALS_LOYALTY_MONDAY = 'boh.goals.loyalty_monday';
export const BOH_GOALS_LOYALTY_TUESDAY = 'boh.goals.loyalty_tuesday';
export const BOH_GOALS_LOYALTY_WEDNESDAY = 'boh.goals.loyalty_wednesday';
export const BOH_GOALS_LOYALTY_THURSDAY = 'boh.goals.loyalty_thursday';
export const BOH_GOALS_LOYALTY_FRIDAY = 'boh.goals.loyalty_friday';
export const BOH_GOALS_LOYALTY_SATURDAY = 'boh.goals.loyalty_saturday';
export const BOH_GOALS_LOYALTY_SUNDAY = 'boh.goals.loyalty_sunday';

export const BOH_GOALS_SALES_THERMOMETER_ALL_DAYS_FLAG = 'boh.goals.sales_thermometer_all_days_flag';
export const BOH_GOALS_SALES_THERMOMETER_ALL_DAYS = 'boh.goals.sales_thermometer_all_days';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_ALL_DAYS = 'boh.goals.stretch_sales_thermometer_all_days';

export const BOH_GOALS_SALES_THERMOMETER_MONDAY = 'boh.goals.sales_thermometer_monday';
export const BOH_GOALS_SALES_THERMOMETER_TUESDAY = 'boh.goals.sales_thermometer_tuesday';
export const BOH_GOALS_SALES_THERMOMETER_WEDNESDAY = 'boh.goals.sales_thermometer_wednesday';
export const BOH_GOALS_SALES_THERMOMETER_THURSDAY = 'boh.goals.sales_thermometer_thursday';
export const BOH_GOALS_SALES_THERMOMETER_FRIDAY = 'boh.goals.sales_thermometer_friday';
export const BOH_GOALS_SALES_THERMOMETER_SATURDAY = 'boh.goals.sales_thermometer_saturday';
export const BOH_GOALS_SALES_THERMOMETER_SUNDAY = 'boh.goals.sales_thermometer_sunday';

export const BOH_GOALS_STRETCH_SALES_THERMOMETER_MONDAY = 'boh.goals.stretch_sales_thermometer_monday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_TUESDAY = 'boh.goals.stretch_sales_thermometer_tuesday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_WEDNESDAY = 'boh.goals.stretch_sales_thermometer_wednesday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_THURSDAY = 'boh.goals.stretch_sales_thermometer_thursday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_FRIDAY = 'boh.goals.stretch_sales_thermometer_friday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_SATURDAY = 'boh.goals.stretch_sales_thermometer_saturday';
export const BOH_GOALS_STRETCH_SALES_THERMOMETER_SUNDAY = 'boh.goals.stretch_sales_thermometer_sunday';

export const BOH_GOALS_UNITS_THERMOMETER_ALL_DAYS_FLAG = 'boh.goals.units_thermometer_all_days_flag';
export const BOH_GOALS_UNITS_THERMOMETER_ALL_DAYS = 'boh.goals.units_thermometer_all_days';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_ALL_DAYS = 'boh.goals.stretch_units_thermometer_all_days';

export const BOH_GOALS_UNITS_THERMOMETER_MONDAY = 'boh.goals.units_thermometer_monday';
export const BOH_GOALS_UNITS_THERMOMETER_TUESDAY = 'boh.goals.units_thermometer_tuesday';
export const BOH_GOALS_UNITS_THERMOMETER_WEDNESDAY = 'boh.goals.units_thermometer_wednesday';
export const BOH_GOALS_UNITS_THERMOMETER_THURSDAY = 'boh.goals.units_thermometer_thursday';
export const BOH_GOALS_UNITS_THERMOMETER_FRIDAY = 'boh.goals.units_thermometer_friday';
export const BOH_GOALS_UNITS_THERMOMETER_SATURDAY = 'boh.goals.units_thermometer_saturday';
export const BOH_GOALS_UNITS_THERMOMETER_SUNDAY = 'boh.goals.units_thermometer_sunday';

export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_MONDAY = 'boh.goals.stretch_units_thermometer_monday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_TUESDAY = 'boh.goals.stretch_units_thermometer_tuesday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_WEDNESDAY = 'boh.goals.stretch_units_thermometer_wednesday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_THURSDAY = 'boh.goals.stretch_units_thermometer_thursday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_FRIDAY = 'boh.goals.stretch_units_thermometer_friday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_SATURDAY = 'boh.goals.stretch_units_thermometer_saturday';
export const BOH_GOALS_STRETCH_UNITS_THERMOMETER_SUNDAY = 'boh.goals.stretch_units_thermometer_sunday';

export const goalsKeysMap = {
    'BOH_GOALS_SPEED_OF_SERVICE': BOH_GOALS_SPEED_OF_SERVICE,
    'BOH_GOALS_ALL_COMPLAINTS_RATIO': BOH_GOALS_ALL_COMPLAINTS_RATIO,
    'BOH_GOALS_OVERALL_SATISFACTION': BOH_GOALS_OVERALL_SATISFACTION,
    'BOH_GOALS_ORDER_ACCURACY': BOH_GOALS_ORDER_ACCURACY,
    'BOH_GOALS_LIKELIHOOD_TO_RETURN': BOH_GOALS_LIKELIHOOD_TO_RETURN,
    'BOH_GOALS_SPEED_OF_SERVICE_SCORE': BOH_GOALS_SPEED_OF_SERVICE_SCORE,

    'BOH_TYPE_LOYALTY_GAUGE_ALL_DAYS_FLAG': BOH_GOALS_LOYALTY_IS_ALL_DAYS,
    'BOH_TYPE_LOYALTY_GAUGE_ALL_DAYS': BOH_GOALS_LOYALTY_ALL_DAYS,

    'BOH_TYPE_LOYALTY_GAUGE_MONDAY': BOH_GOALS_LOYALTY_MONDAY,
    'BOH_TYPE_LOYALTY_GAUGE_TUESDAY': BOH_GOALS_LOYALTY_TUESDAY,
    'BOH_TYPE_LOYALTY_GAUGE_WEDNESDAY': BOH_GOALS_LOYALTY_WEDNESDAY,
    'BOH_TYPE_LOYALTY_GAUGE_THURSDAY': BOH_GOALS_LOYALTY_THURSDAY,
    'BOH_TYPE_LOYALTY_GAUGE_FRIDAY': BOH_GOALS_LOYALTY_FRIDAY,
    'BOH_TYPE_LOYALTY_GAUGE_SATURDAY': BOH_GOALS_LOYALTY_SATURDAY,
    'BOH_TYPE_LOYALTY_GAUGE_SUNDAY': BOH_GOALS_LOYALTY_SUNDAY,

    'BOH_TYPE_THERMOMETER_SALES_ALL_DAYS_FLAG': BOH_GOALS_SALES_THERMOMETER_ALL_DAYS_FLAG,
    'BOH_TYPE_THERMOMETER_SALES_ALL_DAYS': BOH_GOALS_SALES_THERMOMETER_ALL_DAYS,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_ALL_DAYS': BOH_GOALS_STRETCH_SALES_THERMOMETER_ALL_DAYS,

    'BOH_TYPE_THERMOMETER_SALES_MONDAY': BOH_GOALS_SALES_THERMOMETER_MONDAY,
    'BOH_TYPE_THERMOMETER_SALES_TUESDAY': BOH_GOALS_SALES_THERMOMETER_TUESDAY,
    'BOH_TYPE_THERMOMETER_SALES_WEDNESDAY': BOH_GOALS_SALES_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_THERMOMETER_SALES_THURSDAY': BOH_GOALS_SALES_THERMOMETER_THURSDAY,
    'BOH_TYPE_THERMOMETER_SALES_FRIDAY': BOH_GOALS_SALES_THERMOMETER_FRIDAY,
    'BOH_TYPE_THERMOMETER_SALES_SATURDAY': BOH_GOALS_SALES_THERMOMETER_SATURDAY,
    'BOH_TYPE_THERMOMETER_SALES_SUNDAY': BOH_GOALS_SALES_THERMOMETER_SUNDAY,

    'BOH_TYPE_THERMOMETER_SALES_STRETCH_MONDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_MONDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_TUESDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_TUESDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_WEDNESDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_THURSDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_THURSDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_FRIDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_FRIDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_SATURDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_SATURDAY,
    'BOH_TYPE_THERMOMETER_SALES_STRETCH_SUNDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_SUNDAY,

    'BOH_TYPE_THERMOMETER_SALES_UNITS_ALL_DAYS_FLAG': BOH_GOALS_UNITS_THERMOMETER_ALL_DAYS_FLAG,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_ALL_DAYS': BOH_GOALS_UNITS_THERMOMETER_ALL_DAYS,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_ALL_DAYS': BOH_GOALS_STRETCH_UNITS_THERMOMETER_ALL_DAYS,

    'BOH_TYPE_THERMOMETER_SALES_UNITS_MONDAY': BOH_GOALS_UNITS_THERMOMETER_MONDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_TUESDAY': BOH_GOALS_UNITS_THERMOMETER_TUESDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_WEDNESDAY': BOH_GOALS_UNITS_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_THURSDAY': BOH_GOALS_UNITS_THERMOMETER_THURSDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_FRIDAY': BOH_GOALS_UNITS_THERMOMETER_FRIDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_SATURDAY': BOH_GOALS_UNITS_THERMOMETER_SATURDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_SUNDAY': BOH_GOALS_UNITS_THERMOMETER_SUNDAY,

    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_MONDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_MONDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_TUESDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_TUESDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_WEDNESDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_THURSDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_THURSDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_FRIDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_FRIDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_SATURDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_SATURDAY,
    'BOH_TYPE_THERMOMETER_SALES_UNITS_STRETCH_SUNDAY': BOH_GOALS_STRETCH_UNITS_THERMOMETER_SUNDAY,

    'BOH_TYPE_PERCENTAGE_TO_GOAL_ALL_DAYS_FLAG': BOH_GOALS_SALES_THERMOMETER_ALL_DAYS_FLAG,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_ALL_DAYS': BOH_GOALS_SALES_THERMOMETER_ALL_DAYS,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_ALL_DAYS': BOH_GOALS_STRETCH_SALES_THERMOMETER_ALL_DAYS,

    'BOH_TYPE_PERCENTAGE_TO_GOAL_MONDAY': BOH_GOALS_SALES_THERMOMETER_MONDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_TUESDAY': BOH_GOALS_SALES_THERMOMETER_TUESDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_WEDNESDAY': BOH_GOALS_SALES_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_THURSDAY': BOH_GOALS_SALES_THERMOMETER_THURSDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_FRIDAY': BOH_GOALS_SALES_THERMOMETER_FRIDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_SATURDAY': BOH_GOALS_SALES_THERMOMETER_SATURDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_SUNDAY': BOH_GOALS_SALES_THERMOMETER_SUNDAY,

    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_MONDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_MONDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_TUESDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_TUESDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_WEDNESDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_WEDNESDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_THURSDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_THURSDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_FRIDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_FRIDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_SATURDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_SATURDAY,
    'BOH_TYPE_PERCENTAGE_TO_GOAL_STRETCH_SUNDAY': BOH_GOALS_STRETCH_SALES_THERMOMETER_SUNDAY
};

export const defaultValues = {
    'boh.goals.overall_satisfaction': 0.9,
    'boh.goals.order_accuracy': 0.9,
    'boh.goals.likelihood_to_return': 0.9,
    'boh.goals.speed_of_service_score': 0.9,

    'boh.goals.sales_thermometer_all_days': 1000,
    'boh.goals.sales_thermometer_monday': 1000,
    'boh.goals.sales_thermometer_tuesday': 1000,
    'boh.goals.sales_thermometer_wednesday': 1000,
    'boh.goals.sales_thermometer_thursday': 1000,
    'boh.goals.sales_thermometer_friday': 1000,
    'boh.goals.sales_thermometer_saturday': 1000,
    'boh.goals.sales_thermometer_sunday': 1000,

    'boh.goals.units_thermometer_all_days': 10,
    'boh.goals.units_thermometer_monday': 10,
    'boh.goals.units_thermometer_tuesday': 10,
    'boh.goals.units_thermometer_wednesday': 10,
    'boh.goals.units_thermometer_thursday': 10,
    'boh.goals.units_thermometer_friday': 10,
    'boh.goals.units_thermometer_saturday': 10,
    'boh.goals.units_thermometer_sunday': 10,
    
    'boh.goals.loyalty_all_days': 80,
    'boh.goals.loyalty_monday': 80,
    'boh.goals.loyalty_tuesday': 80,
    'boh.goals.loyalty_wednesday': 80,
    'boh.goals.loyalty_thursday': 80,
    'boh.goals.loyalty_friday': 80,
    'boh.goals.loyalty_saturday': 80,
    'boh.goals.loyalty_sunday': 80,

    'boh.goals.sales_thermometer_all_days_flag': { 'key': 'boh.goals.sales_thermometer_all_days_flag', 'value': true, 'reset': false },
    'boh.goals.units_thermometer_all_days_flag': { 'key': 'boh.goals.units_thermometer_all_days_flag', 'value': true, 'reset': false },
    'boh.goals.loyalty_is_all_days_flag': { 'key': 'boh.goals.loyalty_is_all_days_flag', 'value': true, 'reset': false }
};