import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { Redish } from 'libs/css';

const NotAllowedMessage = (props) => {
    return <div className='d-flex flex-column w-100 justify-content-center align-items-center h-100'>
        <h3>403</h3>

        <Card>
            <CardBody>
                {props.message || <h5 style={{ color: Redish }}>Not allowed</h5>}
            </CardBody>
        </Card>
    </div>;
};

export default NotAllowedMessage;
