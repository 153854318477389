import { UPLink } from 'Layout/PageOrModal';
import React from 'react';

export function SpotlightLink ({ spotlight: { id }, ...props }) {
    return <UPLink path={`/spotlight-${id}`} {...props}>{props.children}</UPLink>;
}

export function ScreenLink ({ screen: { id }, ...props }) {
    return <UPLink path={`/screen-${id}`} {...props}>{props.children}</UPLink>;
}

export function ContentUserLink ({ user: { id }, ...props }) {
    return <UPLink path={`/contentuser-${id}`} {...props}>{props.children}</UPLink>;
}

export function ContentLink ({ content: { id }, ...props }) {
    return <UPLink path={`/content-${id}`} {...props}>{props.children}</UPLink>;
}

export function InstagramTokenLink ({ token: { id }, ...props }) {
    return <UPLink path={`/igt-${id}`} {...props}>{props.children}</UPLink>;
}

export function NoteLink ({ note: { id }, ...props }) {
    return <UPLink path={`/note-${id}`} {...props}>{props.children}</UPLink>;
}

export function TrackingCode ({ tracking_code: { id }, ...props }) {
    return <UPLink path={`/trackingcode-${id}`} {...props}>{props.children}</UPLink>;
}