import React from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap-daterangepicker/daterangepicker.css';

export function DatetimeRangeFilter ({ initialSettings, datetimeRange, setDatetimeRange, customRanges, timePicker = true, alwaysShowCalendars = true, linkedCalendars = false, disabled = false }) {
    let ranges = undefined;
    if (customRanges !== false) {
        ranges= customRanges ?? {
            'Last 15 minutes': [moment().subtract(15, 'minutes'), moment()],
            'Last hour': [moment().subtract(1, 'hour'), moment()],
            'Today': [moment().startOf('day'), moment()],
            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Anytime': []
        }
    }

    const settings = {
        timePicker,
        alwaysShowCalendars,
        linkedCalendars,
        ranges,
        ...datetimeRange,
        ...initialSettings
    };

    return <>
        <DateRangePicker
            initialSettings={settings}
            onCallback={(start, end, label) => {
                if (label === 'Anytime') setDatetimeRange({ label })
                else setDatetimeRange({ start, end, label });
            }}
        >
            <button className={'btn btn-outline-dark btn-sm'} disabled={disabled}>
                <FontAwesomeIcon icon={faClock}/>
            </button>
        </DateRangePicker>

        <small className="text-break ms-3">
            {datetimeRange.label && datetimeRange.label !== 'Custom Range'
                ? datetimeRange.label
                : <>
                    {datetimeRange.start.format('L')} <small>{datetimeRange.start.format('LTS')}</small> -&nbsp;
                    {datetimeRange.end.format('L')} <small>{datetimeRange.end.format('LTS')}</small>
                </>
            }
        </small>
    </>;
}