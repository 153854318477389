export const GRAPHQL_ENDPOINT = process.env.REACT_APP_DEV_GRAPHQL_ENDPOINT;
export const BASE_PATH = process.env.REACT_APP_BASE_PATH;
export const STATIC_CDN_PATH = process.env.REACT_APP_STATIC_CDN;
export const APOLLO_EXTENSION = !!+process.env.REACT_APP_APOLLO_EXTENSION;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENABLED = !!+process.env.REACT_APP_SENTRY_ENABLED;
export const NODE_ENV = process.env.NODE_ENV;
export const SIGNER_URL = process.env.REACT_APP_SIGNER_URL;
export const PLUGIN_TESTER_URL = process.env.REACT_APP_PLUGIN_TESTER_URL;
export const OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_ISSUER_URL;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID_BACKEND;
export const OKTA_URL_BASE = process.env.REACT_APP_OKTA_URL_BASE;
export const REACT_APP_SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_URL;
export const AUTH_SCOPES = ['openid', 'backend', 'email'];

export const issueTypes = [
    { value: 'other', label: 'Other' },
    { value: 'device_connectivity', label: 'Device - Connectivity' },
    { value: 'device_install_replace', label: 'Device - Install / Replace' },
    { value: 'device_health_outreach', label: 'Device - Health Outreach' },
    { value: 'finance', label: 'Finance' },
    { value: 'churned', label: 'Churned' },
    { value: 'streaming_event', label: 'Streaming Event' },
];

export const outreachTypes = [
    { value: 'other', label: 'Other' },
    { value: 'chat', label: 'Chat' },
    { value: 'check-in', label: 'Check-in' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone call' },
    { value: 'text', label: 'Text' }
];

export const AVAILABLE_EXPERIENCES = ['CONNECT', 'SHIFT', 'HEALTH'];
