import React from 'react';

function LiveEventLogo () {
    return (<svg width="18px" height="13px" viewBox="0 0 18 13" version="1.1">
        <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
            <g transform="translate(-16.000000, -471.000000)" fill="currentColor" fillRule="nonzero">
                <path d="M29.59,471.252 C29.896,470.946 30.382,470.946 30.688,471.252 C34.018,474.582 34.018,480.018 30.67,483.366 C30.526,483.528 30.328,483.6 30.13,483.6 C29.932,483.6 29.734,483.51 29.59,483.366 C29.284,483.06 29.284,482.574 29.59,482.268 C32.326,479.532 32.326,475.086 29.59,472.35 C29.284,472.044 29.284,471.558 29.59,471.252 Z M18.592,471.252 C18.898,470.946 19.384,470.946 19.69,471.252 C19.996,471.558 19.996,472.044 19.69,472.35 C16.954,475.086 16.954,479.532 19.672,482.25 C19.978,482.556 19.978,483.042 19.672,483.348 C19.528,483.51 19.33,483.582 19.132,483.582 C18.934,483.582 18.736,483.492 18.592,483.348 C15.262,480.018 15.262,474.582 18.592,471.252 Z M27.016,473.808 C27.322,473.502 27.808,473.502 28.114,473.808 C30.04,475.734 30.058,478.866 28.096,480.792 C27.952,480.954 27.754,481.026 27.556,481.026 C27.358,481.026 27.16,480.936 27.016,480.792 C26.71,480.486 26.71,480 27.016,479.694 C28.348,478.38 28.33,476.22 27.016,474.906 C26.71,474.6 26.71,474.114 27.016,473.808 Z M21.148,473.808 C21.454,473.502 21.94,473.502 22.246,473.808 C22.552,474.114 22.552,474.6 22.246,474.906 C20.932,476.22 20.932,478.38 22.228,479.694 C22.534,480 22.534,480.486 22.228,480.792 C22.084,480.954 21.886,481.026 21.688,481.026 C21.49,481.026 21.292,480.936 21.148,480.792 C19.222,478.866 19.222,475.734 21.148,473.808 Z M24.64,475.986 C25.3657022,475.986 25.954,476.574298 25.954,477.3 C25.954,478.025702 25.3657022,478.614 24.64,478.614 C23.9142978,478.614 23.326,478.025702 23.326,477.3 C23.326,476.574298 23.9142978,475.986 24.64,475.986 Z"></path>
            </g>
        </g>
    </svg>);

}

export default LiveEventLogo;