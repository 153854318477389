import React from 'react';
import { Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

const SectionHeader = ({ title, children, noHeader = false }) => <>
    {title && <Helmet><title>{title}</title></Helmet>}
    <Row className="my-3">
        {!noHeader && title && <Col><h3 className="m-0">{title}</h3></Col>}
        {children}
    </Row>
    <hr/>
</>;

export default SectionHeader;
