import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { genericMutationCompletedHelper, genericMutationErrorHandler } from 'libs/genericMutationHandler';

import DragAndDrop from './DragAndDrop';

export default function AvatarUploader ({ user, children }) {

    const [avatarMutation] = useMutation(gql`mutation setAvatar($user_id: Int!, $avatar: JSON) { userMutation(user_id: $user_id) { mutationResult: saveSetting(key: "avatar_url", value: $avatar) { successful message user { id avatar_url }}}}`, {
        variables: { user_id: user.id },
        onCompleted: ({ userMutation: { mutationResult } }) => genericMutationCompletedHelper(mutationResult),
        onError: genericMutationErrorHandler
    });
    const setAvatar = (avatar) => avatarMutation({ variables: { avatar } });

    return <DragAndDrop mutate={setAvatar} path="user_avatars/" showDelete>
        {children}
    </DragAndDrop>;
}