import React, { useState } from 'react';
import { Button, ButtonGroup, Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ConfirmDialog from './ConfirmDialog';

/**
 * @param children - Basic version: The children should be an array of { name, callback, modal?: { props } }. The actions can also have a confirmation modal
 * @param id - A different string for each row
 * @returns 
 */
export default function TableActions ({ children, id }) {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = (shouldBeOpen) => setPopoverOpen(shouldBeOpen ?? !popoverOpen);

    return <span onMouseEnter={() => toggle(true)} onMouseLeave={() => toggle(false)} className="px-2">
        <Button id={id} color="link">
            <FontAwesomeIcon icon={faEllipsisV} fixedWidth/>
        </Button>
        <Popover placement="left" target={id} isOpen={popoverOpen} hideArrow>
            <ButtonGroup vertical>
                {children.map((action, i) => {
                    const border = i > 0 ? 'border-top' : '';
                    const textColor = action.modal?.destructive ? 'text-danger' : '';
                    return action.modal ? (
                        <ConfirmDialog key={`${action.modal.title}-${action.modal.message}`} title={action.modal.title} message={action.modal.message} confirmAction={action.callback} confirmText={action.modal.confirmText} destructive={action.modal.destructive}>
                            <Button color="link" size="sm" className={`${border} text-decoration-none py-2 px-4 ${textColor}`} disabled={action.disabled}>
                                {action.name}
                            </Button>
                        </ConfirmDialog>
                    ) : (
                        <Button
                            key={i}
                            onClick={() => {
                                toggle(false);
                                action.callback();
                            }}
                            color="link"
                            size="sm"
                            disabled={action.disabled}
                            className={`${border} text-decoration-none py-2 px-4`}
                        >
                            {action.name}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </Popover>
    </span>
}
