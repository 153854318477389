import React, { useCallback, useEffect, useState } from 'react';
import { DarkSlateBlue, Greenish, Grey2, Greyish, Redish } from 'libs/css';
import { faCheckCircle, faCircleNotch, faSave, faSync, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import IconButton from './IconButton';
import ToolTipCito from './ToolTipCito';
import { Label } from 'reactstrap';

export function InlineCheckBox ({ value, mutate, disabled, label, tooltip }) {
    const [newValue, setNewValue] = useState(value);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => setNewValue(value), [value]);
    useEffect(() => setDirty(value !== newValue), [setDirty, value, newValue]);

    const save = useCallback((value) => {
        setSaving(true);
        mutate(value)
            .then(() => {
                setDirty(false);
                setSaving(false);
            });
    }, [mutate, newValue, setDirty, setSaving]);

    const cleanup = useCallback(() => {
        setNewValue(value);
        setDirty(false);
    }, [value, setSaving, setDirty]);

    const handleClick = () => setNewValue(!newValue);

    let icon = faCircle;
    let color = Grey2;

    if (newValue === true) {
        icon = faCheckCircle;
        color = Greenish;
    } else if (newValue === false) {
        icon = faTimesCircle;
        color = Redish;
    }

    return <div className="d-flex justify-content-between mb-3">
        <small>
            <Label onClick={handleClick} check={!!value}>{label}</Label>
            {tooltip && <ToolTipCito text={tooltip}/>}
        </small>
        <span>
            {!disabled && <>
                {dirty && <>
                    <IconButton color={Grey2} icon={faSync} onClick={cleanup} size="sm" title="Revert to saved"/>
                    <IconButton color={DarkSlateBlue} icon={saving ? faCircleNotch : faSave} spin={saving} onClick={() => save(newValue)} size="sm" title="Save"/>
                </>}
                {value !== undefined && <IconButton onHoverColor={Redish} color={Greyish} icon={saving ? faCircleNotch : faTrash} spin={saving} onClick={() => save(null)} size="sm" title="Delete value"/>}
            </>}
            <IconButton icon={icon} color={color} onClick={handleClick}/>
        </span>
    </div>;
}