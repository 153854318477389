import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { DarkSlateBlue, Grey2, Redish } from 'libs/css';
import { faCircleNotch, faSave, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import JSONInput from './JSONInput';
import IconButton from './IconButton';

function isJSON(str) {
    if (!str) return false;

    if (typeof str === 'object') return true;

    try {
        const parsed = JSON.parse(str);
        if (typeof parsed === 'object') return true;
    } catch (error) {
    }

    return false;
}

export function InlineInput ({ value = '', mutate, disabled, placeholder, maxLength }) {
    const [newValue, setNewValue] = useState(value);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => setNewValue(value), [value]);
    useEffect(() => setDirty(value !== newValue), [setDirty, value, newValue]);

    const save = useCallback((value) => {
        setSaving(true);
        mutate(value)
            .then(() => {
                setDirty(false);
                setSaving(false);
            });
    }, [mutate, newValue, setDirty, setSaving]);

    const cleanup = useCallback(() => {
        setNewValue(value);
        setDirty(false);
    }, [value, setSaving, setDirty]);

    const Actions = () => <InputGroupText>
        {dirty && <>
            <IconButton className="m-0 p-0 me-2" onClick={cleanup} icon={faSync} color={Grey2} />
            <IconButton className="m-0 p-0" onClick={() => save(newValue)} color={DarkSlateBlue} icon={saving ? faCircleNotch : faSave} spin={saving} />
        </>}
        {value && <IconButton className="m-0 p-0" color={Redish} icon={saving ? faCircleNotch : faTrash} spin={saving} onClick={() => save(null)} />}
    </InputGroupText>;

    return (<InputGroup>
        {isJSON(value)
                ? <JSONInput disabled={disabled} onChange={(obj) => obj.valid && setNewValue(obj.json)} value={!!newValue ? (typeof newValue === 'string' ? JSON.parse(newValue) : newValue) : {}}/>
                : <Input value={newValue} onChange={(event) => setNewValue(event.target.value)} disabled={disabled} placeholder={placeholder} maxLength={maxLength}/>}

        {!disabled && (value || dirty) && <Actions/>}
    </InputGroup>);
}
