import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments';

export const ADD_ORG_USER = gql`mutation backendAddUserToOrg($org_id: Int!, $user_id: Int! $groups: [Int]) {
    orgMutation(organization_id: $org_id) {
        addUser(user_id: $user_id groups: $groups) {
            status successful message
            UserOrganization {
                organization { id }
                user { ...userFragment }
            }
        }
    }
}
${USER_FRAGMENT}`;

export const GET_USERS = gql`query backendGetUsers {
    backend { id users { ...userFragment } }
}
${USER_FRAGMENT}`;

export const UNLINK_ORG_USER = gql`
    mutation backendRemoveUserFromOrg($org_id: Int!, $user_id: Int!) {
        orgMutation(organization_id: $org_id) {
            removeUser(user_id: $user_id) {
                status successful message
            }
        }
    }
`;

export const GET_USERS_EMAILS = gql`query getUserEmails($org_id: Int!) {
    Organization(id:$org_id) {
        id
        name
        users {
            id
            username
            email
        }
        child_organizations(include_descendants: true) {
            id
            name
            users {
                id
                username
                email
            }
        }
    }
}
`;

export const GET_USER_GROUPS = gql`
    query getUserGroups($user_id: Int!) {
        backend {
            id
            user(id: $user_id) {
                ...userFragment
                UserOrganizations {
                    organization {
                        id
                    }
                    groups {
                        id
                        name display_name
                        description
                    }
                }
            }
        }
    }
${USER_FRAGMENT}`;

export const GET_NOTIFICATIONS = gql`
    query getNotifications($cursor: String $dismissed: Boolean) {
        user: me { 
            id
            unreadNotifications
            Notifications(last: 100 after: $cursor dismissed: $dismissed) {
                pageInfo {
                    endCursor
                    hasNextPage
                }
                edges {
                    node {
                        id
                        message
                        createdAt
                        dismissed
                    }
                }
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = gql`
    mutation updateNotification ($user_id: Int! $notification_id: Int! $dismissed: Boolean!){
        userMutation(user_id: $user_id) {
            updateNotification(id: $notification_id, dismissed: $dismissed) {
                successful
                notification {
                    id
                    dismissed
                }
            }
        }
    }
`;
    
export const MARK_NOTIFICATIONS_READ = gql`
    mutation bulkUpdateNotifications ($user_id: Int!) {
        userMutation(user_id: $user_id) {
            updateAllNotifications(dismissed: true) {
                successful
                notifications {
                    id
                    dismissed
                }
            }
        }
    }
`;